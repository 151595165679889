<template>
  <v-container>
  <v-card class="view" v-if="!showShareView">
    <v-card-title>
      <v-toolbar flat color="white">
        <v-toolbar-title v-if="!isPhone">{{$t(`profil.${this.lang}.expert_search`)}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field v-if="showQuick" 
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t(`profil.${lang}.search`)"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          small
          @click="hide()"
        >{{hideButtonText}}
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text v-if="showAdvance">
      <AdvancedSearch/>
    </v-card-text>
    <div class="text-center" v-if="loading">
      <v-progress-circular
              size="100"
              indeterminate>
      </v-progress-circular>
    </div>


    <v-row dense>
      <v-col
        v-for="expert in searchFilter"
        :key="expert.userDto.userID"
        :cols="isPhone ? '12' : '4'"
      >
        <SkillCard :expert="expert" @share="share($event)"/>
      </v-col>
    </v-row>
  </v-card>
  <Publish :shareExpert="shareExpert" v-if="showShareView" @deshare="deshare()"></Publish>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import SkillCard from '../components/customItems/SkillCard.vue'
  import AdvancedSearch from '../components/customItems/AdvancedSearch.vue'
  import Publish from '../components/customItems/Publish'
  export default {
    data: () => ({
      showQuick: true,
      showAdvance: false,
      showShareView: false,
      previewShare: false,
      shareExpert: null,
      dialog: false,
      expert:{},
      search:"",
    }),  
    computed: {
      ...mapState({
        experts: state => state.searchExpert.all.items,
        loading: state => state.searchExpert.all.loading,
        isPhone: state => state.general.isPhone,
        lang: state => state.general.profilLang,
      }),
      phoneResponsive() {
        return this.isPhone ? 12 : 4
      },
      searchFilter() {
        if (null != this.experts) {
          return this.experts.filter(p => p.userDto.firstname.toLowerCase().concat(" ", p.userDto.lastname.toLowerCase()).includes(this.search.toLowerCase().trim()) || p.userDto.lastname.toLowerCase().concat(" ", p.userDto.firstname.toLowerCase()).includes(this.search.toLowerCase().trim()))
          //return this.experts.filter(p => p.userDto.firstname.toLowerCase().includes(this.search.toLowerCase().trim()) || p.userDto.lastname.toLowerCase().includes(this.search.toLowerCase().trim()));
        }
        return [];
      },
      hideButtonText() {
        return true == this.showAdvance ? this.$t(`profil.${this.lang}.global_search`) : this.$t(`profil.${this.lang}.advanced_search`);
      },
      
    },
    created() {
      this.getAll(this.$i18n.locale);
      this.getSkills(this.$i18n.locale);
    },
    methods: {
      ...mapActions('searchExpert', ['getAll','getSkills']),
      open(expert) {
        this.expert = expert;
      },
      hide() {
        this.showAdvance = !this.showAdvance;
        this.showQuick = !this.showQuick;
        this.search = "";
        this.getAll(this.$i18n.locale);
      },
      share(expert){
        this.shareExpert = expert;
        this.showShareView = true;
      },
      deshare(){
        this.shareExpert = null;
        this.showShareView = false;
      }
    },
    components: {
      SkillCard,
      AdvancedSearch,
      Publish
    },
  }
</script>
<style scoped>
.view {
    width:90%;
    height:90%;
  }
</style>
