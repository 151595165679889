<template>
  <v-dialog 
    v-model="dialog" 
    max-width="500px" 
    persistent>
    <template v-slot:activator="{  }" >
      <v-btn color="primary" dark class="mb-2" @click="close" v-show="isEditable">{{$t('i18n_new')}}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('i18n_conf_edit_skill')}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="skillForm"
            v-model="formValid">
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field 
                  :label="$t('i18n_conf_skill')"
                  v-model="toEdit.name"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="relevanz"
                  v-model="toEdit.relevance"
                  :label="$t('i18n_conf_relevance')"
                  :rules="formRules"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="ratings"
                  item-text="skaleName"
                  return-object
                  v-model="toEdit.rating"
                  :label="$t('i18n_conf_rating')"
                  :rules="formRules"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  :items="classifications.filter(classification => classification.leaf)"
                  :filter="customFilter"
                  v-model="toEdit.classificationsList[0]"
                  clearable
                  return-object
                  :label="$t('i18n_conf_category')"
                  :rules="formRules">
                  <template v-slot:selection="data">
                    {{ constructClassificationHierarchy(data.item) }}
                  </template>
                  <template v-slot:item="data">
                    {{ constructClassificationHierarchy(data.item) }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                        auto-grow
                        :label="$t('i18n_conf_description')"
                        v-model="toEdit.description"
                ></v-textarea>
              </v-col>
  <!--                    TODO Consider if multiple classifications are necessary. Commented out until then.-->
  <!--                    <v-col cols="12" sm="6" md="6">-->
  <!--                      <v-btn -->
  <!--                        large-->
  <!--                        icon-->
  <!--                        color="green"-->
  <!--                        @click="numberClassifications++">-->
  <!--                        <v-icon>mdi-plus</v-icon>-->
  <!--                      </v-btn>-->
  <!--                      <v-btn -->
  <!--                        v-if="numberClassifications > 1"-->
  <!--                        large-->
  <!--                        icon-->
  <!--                        color="red"-->
  <!--                        @click="numberClassifications&#45;&#45;">-->
  <!--                        <v-icon>mdi-minus</v-icon>-->
  <!--                      </v-btn>-->
  <!--                    </v-col>-->
  <!--                    <v-col -->
  <!--                      v-for="index in (numberClassifications - 1)"-->
  <!--                      :key="index"-->
  <!--                      cols="12" sm="6" md="6">-->
  <!--                      <v-select-->
  <!--                        :items="classifications.filter(classification => classification.leaf)"-->
  <!--                        v-model="newSkill.classificationsList[index]"-->
  <!--                        item-text="name"-->
  <!--                        return-object-->
  <!--                        label="Klassifikation"-->
  <!--                        :rules="formRules"-->
  <!--                      ></v-select>-->
  <!--                    </v-col>-->
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"  @click="close">{{$t('i18n_cancel')}}</v-btn>
        <v-btn color="primary" @click="save">{{$t('i18n_save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from "vuex";
  import { constructClassificationHierarchy } from "../../utils/configurationPage.utils";

  export default {
    data: () => ({
      numberClassifications:1,
      formValid:true,
      formRules: [
                v => !!v || "Field is required"
            ]
    }),
    props: {
      toEdit: Object,
      dialog: Boolean
    },
    computed: {
      ...mapState({
        classifications: state => state.configuration.classifications,
        ratings: state => state.configuration.rating,
        relevanz: state => state.general.relevanz,
        isEditable: state => state.general.selectedPageEditable
      }),
    },
    methods: {
      close() {
        this.numberClassifications = 1;
        this.$emit("fire-close", !this.dialog);
      },
      save() {
        this.$refs.skillForm.validate();
        if(this.formValid) {
          this.toEdit.classificationsList = this.toEdit.classificationsList.slice(0, this.numberClassifications);
          this.$emit("fire-save", this.toEdit);
        }
      },
      customFilter (item, queryText) {
        const textOne = item.name.toLowerCase();
        const searchText = queryText.toLowerCase();

        return textOne.indexOf(searchText) > -1;
      },
      constructClassificationHierarchy
    }
  }
</script>
