<template>  
        <v-app>
            <title>{{ metaData.applicationName }}</title>
            <router-view></router-view>
            <v-snackbar
              :value="alert.message"
              :color="`${alert.type}`"
              class="alert"
              app
            >
              {{ alert.message }}
            </v-snackbar>
        </v-app>    
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'app',
    computed: {
        ...mapState({
            alert: state => state.alert,
            isPhone: state => state.general.isPhone,
            metaData: state => state.meta.meta
        })
    },
    created () {
        this.getMetaData();
    },
    methods: {
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        ...mapActions('meta', ['getMetaData'])
    },
    watch: {
        $route (){
            // clear alert on location change
            this.clearAlert();
        }
    } 
};
</script>


<style>
  .theme {
      background: url(./assets/Aequitas.jpg) no-repeat;
      background-size: 100% 100%;
      height: 100%;
  }
  .alert {
    text-align: end;
  }
</style>
