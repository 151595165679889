<template>
    <v-card class="profile" flat>
        <v-card-title>
            Berufserfahrung
        </v-card-title>
        <v-card-text>
                    <v-simple-table v-if="table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Organisation</th>
                                    <th>Position</th>
                                    <th>Start</th>
                                    <th>Ende</th>
                                    <th v-if="description">Beschreibung</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="experience in experiences" :key="experience.id" class="print-section">
                                    <td>{{experience.organization}}</td>
                                    <td>{{experience.position}}</td>
                                    <td>{{experience.start | formatDate}}</td>
                                    <td>{{experience.end | formatDate}}</td>
                                    <td v-if="description">
                                        <template v-if="formatted">
                                            <VueMarkdown :source="experience.description"></VueMarkdown>
                                        </template>
                                        <template v-else>
                                            {{experience.description}}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-container  v-else-if="timeline">
                                <v-timeline dense>
                                    <v-timeline-item
                                    v-for="experience in experiences" :key="experience.id"
                                    small
                                    :color="template.color"
                                    class="print-section"
                                    >
                                    
                                    <v-card>
                                        <v-card-title>
                                            {{experience.organization}}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{experience.start | formatDate}} - {{experience.end | formatDate}}
                                        </v-card-subtitle>
                                        <v-card-text>
                                            {{experience.description}}
                                        </v-card-text>
                                    </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                                
                    </v-container>
                    <v-list dense v-else>
                        <template v-for="(item, index) in experiences">
                        <v-list-item class="showhim print-section"
                            :key="item.id">
                            <v-list-item-icon>
                                <v-icon>mdi-briefcase</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                            <h3 style="margin-bottom: 5px;">{{item.organization}}</h3>
                            <v-list-item-subtitle>{{item.position}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="margin-bottom: 15px;">{{item.start | formatDate}} {{ item.end ? '-' : ''}} {{item.end | formatDate}}</v-list-item-subtitle>
                            <template v-if="description">
                                <div v-if="formatted">
                                    <VueMarkdown :source="item.description"></VueMarkdown>
                                </div>
                                <div v-else>
                                    {{item.description}}
                                </div>
                            </template>
                            
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            v-if="index + 1 < experiences.length"
                            :key="`${index}-divider`">
                        </v-divider>
                        </template>
                    </v-list>
        </v-card-text>

    </v-card>
</template>
 
<script>
    //import { mapState } from "vuex";
    import VueMarkdown from 'vue-markdown';
    export default{
        data: () => ({
            
        }),
        props:{
            expert: Object,
            template: Object
        },
        computed: {
            //...mapState({
            //experiences: state => state.users.experience.sort(function(a,b){
            //    return (new Date(a.end) - new Date(b.end)) * -1;
            //    })
            //}),
            experiences(){
                return this.expert.experience
            },
            table(){
                return this.template.experienceLayout === 'TABLE';
            },
            timeline(){
                return this.template.experienceLayout === 'TIMELINE';
            },
            compact(){
                 return this.template.experienceLayout === 'COMPACT';
            },
            description(){
                return this.template.experienceDescription !== 'NONE';
            },
            formatted(){
                return this.template.experienceDescription === 'FORMATTED_TEXT'
            }
        },
        filters : {
            formatDate(date) {
                if(date) {
                    return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
                }
            }
        },
        components : {
            VueMarkdown
        }
    }
</script>

<style scoped>
.profile {
    width: 100%;
}
.v-timeline-item{
 page-break-inside: avoid;
   visibility: visible;
  height:auto!important;

     overflow-y: hidden;
}
.profile {
    page-break-inside: avoid;
    visibility: visible;
    height: auto!important;
    overflow-y: hidden;
}
</style>
