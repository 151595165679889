<template >
  <v-dialog 
    v-model="dialog" 
    max-width="500px"
    persistent>
    <template v-slot:activator="{ }">
      <v-btn color="primary" dark class="mb-2" @click="close" v-show="isEditable">{{$t('i18n_new')}}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('i18n_conf_edit_language')}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="languageForm"
            v-model="formValid">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  :label="$t('i18n_conf_language')"
                  v-model="toEdit.name"
                  :rules="formRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="relevance"
                  v-model="toEdit.relevance"
                  :label="$t('i18n_conf_relevance')"
                  :rules="formRules"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="ratings"
                  item-text="skaleName"
                  return-object
                  v-model="toEdit.rating"
                  :label="$t('i18n_conf_rating')"
                  :rules="formRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"  @click="close">{{$t('i18n_cancel')}}</v-btn>
        <v-btn color="primary"  @click="save">{{$t('i18n_save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from "vuex";
 
  export default {
    data: () => ({
      formValid:true,
      formRules: [
                v => !!v || "Field is required"
            ]
    }),
    props: {
      toEdit: Object,
      dialog: Boolean
    },
    computed: {
      ...mapState({
        ratings: state => state.configuration.rating,
        relevance: state => state.general.relevanz,
        isEditable: state => state.general.selectedPageEditable
      })
    },
    methods: {
      close() {
        this.$emit("fire-close", !this.dialog);
      },
      save() {
        this.$refs.languageForm.validate()
        if(this.formValid) {
          this.$emit("fire-save", this.toEdit);
        }
      }
    }
  }
</script>
