import { authHeader, hostUrl } from '../router';
import { handleResponse } from "../utils/service.utils";

export const searchExpertService = {
  getAll,
  getExpertsByUser,
  getSkills,
  searchExperts
};

function getAll(code) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(hostUrl() + `/searchAllExperts?code=${code}`, requestOptions).then(handleResponse);
}

function getExpertsByUser(code,userid) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(hostUrl() + `/userExperts/${userid}?code=${code}`, requestOptions).then(handleResponse);
}

function getSkills(code) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  console.log('code is ' + code);
  return fetch(hostUrl() + `/getSkills?code=${code}`, requestOptions).then(handleResponse);
}

function searchExperts(advancedSearch) {
  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(advancedSearch)
  };

  return fetch(hostUrl() + `/searchExperts`, requestOptions).then(handleResponse);
}
