<template >
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{}">
      <v-btn color="primary" dark class="mb-2" @click="close" v-show="isEditable">{{$t('i18n_new')}}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('i18n_conf_edit_rating')}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="ratingForm"
            v-model="formValid">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field 
                  :label="$t('i18n_conf_rating_name')"
                  :rules="formRules"
                  v-model="toEdit.skaleName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="maxSkales"
                  :rules="formRules"
                  v-model="toEdit.skale"
                  :label="$t('i18n_conf_max_scale')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">

                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-switch
                      v-model="toEdit.ratingMode"
                      flat
                      :label="$t('i18n_conf_rating_mode')"
                      v-on="on">
                    </v-switch>
                  </template>
                  <span>Schalten sie den Modus <b>aus</b> um die unten angegebene
                    Bewertungswerte anzuzeigen oder <b>an</b> um die Bewertung
                    durch Zahlen von 1 bis {{ toEdit.skale }} darzustellen.
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                        :label="$t('i18n_conf_color')"
                        :items="COLORS"
                        v-model="toEdit.color">
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea 
                  :label="$t('i18n_conf_description')"
                  v-model="toEdit.description"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6" 
                  v-for="index in numberOfRatings" 
                  :key="index">
                <v-text-field 
                  v-model="toEdit.allowedValues[index]"
                  :rules="formRules"
                  :label="ratingLabel(index)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"  @click="close">{{$t('i18n_cancel')}}</v-btn>
        <v-btn color="primary" @click="save">{{$t('i18n_save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from "vuex";
  
  import { COLORS } from "../../utils/color.utils";

  export default {
    data: () => ({
      formValid:true,
      formRules: [
                v => !!v || "Field is required"
            ],
      maxSkales:[2,3,4,5,6,7,8,9,10],
      COLORS
    }),
    props: {
      toEdit: Object,
      dialog: Boolean,
      selectedParent: Object
    },
    computed: {
      ...mapState({
        ratings: state => state.configuration.rating,
        isEditable: state => state.general.selectedPageEditable
      }),
      numberOfRatings() {
        return parseInt(this.toEdit.skale) > 0 ? parseInt(this.toEdit.skale) : 0;
      }
    },
    methods: {
      close() {
        this.$emit("fire-close", !this.dialog);
      },
      save() {
        this.$refs.ratingForm.validate();
        if(this.formValid) {
          this.removeObsoleteEntries(this.toEdit.allowedValues, this.toEdit.skale);
           this.$emit("fire-save", this.toEdit);
        }
      },
      removeObsoleteEntries(allowedValues, scale) {
        if(scale < Object.keys(allowedValues).length){
          for(let i = scale + 1; i <= Object.keys(allowedValues).length + 1; i++){
            delete allowedValues[i];
          }
        }
      },
      ratingLabel(index) {
        return this.$t('i18n_conf_rating') + " " + index;
      }
    }
  }
</script>
