<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-if="expert.userDto"
      @click="load(expert)"
      class="skill print-p"
      :elevation="hover ? 12 : 2"
      :class="{ 'on-hover': hover }"
    >
      <v-card-title>
        <v-avatar align="center" justify="center">
          <v-img
            v-if="expert.userDto.image"
            :src="expert.userDto.image"
          ></v-img>
          <v-icon v-else> mdi-account-off</v-icon>
        </v-avatar>
        <v-spacer></v-spacer>
        <span
          v-text="expert.userDto.firstname + ' ' + expert.userDto.lastname"
        ></span>
        <v-spacer></v-spacer>
        <v-btn icon @click="share(expert)">
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <table>
          <tbody>
            <tr>
              <td><v-icon>mdi-briefcase</v-icon></td>
              <td>{{ expert.userDto.position }}</td>
            </tr>
            <tr>
              <td><v-icon>mdi-school</v-icon></td>
              <td>{{ expert.userDto.degree }}</td>
            </tr>
            <tr>
              <td><v-icon>mdi-email</v-icon></td>
              <td>{{ expert.userDto.email }}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn icon @click="compare(expert)">
          <v-icon>mdi-compare-horizontal</v-icon>
        </v-btn> -->
      </v-card-actions>
      <SkillsPopup
        :dialog="dialog"
        :expert="expert"
        @closeDetailDialog="closeDetailDialog"
      />
    </v-card>
  </v-hover>
</template>

<script>
import SkillsPopup from "../customDialogs/SkillsPopup.vue";
import { sortSkillsByLevel } from "../../utils/profilePage.utils";
import { searchExpertService } from "../../services/searchExpert.service";

export default {
  props: {
    expert: Object,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    async load(expert) {
      const code = this.$i18n.locale;
      const userid = expert.userDto.userID;
      const result = searchExpertService.getExpertsByUser(code, userid);
      this.expert = await result;
      this.dialog = true;
    },
    closeDetailDialog(dialog) {
      this.dialog = dialog;
    },
    share(expert) {
      this.$emit("share", expert);
    },
    compare(expert) {
      this.$emit("compare", expert);
    },
    sortSkillsByLevel,
  },
  components: {
    SkillsPopup,
  },
};
</script>
<style scoped>
.skill {
  height: 100%;
  color: blue !important ;
}
</style>
