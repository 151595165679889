<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent 
      width="800">
      <v-card>
        <v-card-title
          primary-title
        >
          {{$t(`profil.${this.lang}.languages`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-divider></v-divider>

        <v-card-text>
          <v-container 
            fluid>
            <v-autocomplete
              v-model="selectedLanguages"
              :items="fillSelectedList"
              item-text="name"
              :label="$t(`profil.${this.lang}.my_languages`)"
              chips
              multiple
            >
              <template v-slot:item="data">
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox 
                      :input-value="{
                        data:data.item,
                        selected:selectedLanguages, 
                        userId:basicData.userID
                        } | selected"
                      @change="selectLanguage(data.item)">
                    </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.name}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-container>

          <v-container  v-for="item in selectedLanguages"
            :key="item.name"
            fluid >
            <v-toolbar
              flat>
              <v-btn
                color="red" 
                @click="deleteSelected(item)"
                icon>
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <v-toolbar-title>{{item.name}}</v-toolbar-title>
              <v-spacer/>
              <v-tooltip top open-delay="1000">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-rating
                      background-color="blue"
                      color="primary"
                      full-icon="mdi-checkbox-blank"
                      empty-icon="mdi-checkbox-blank-outline"
                      dense
                      v-model="item.level"
                      :length="item.rating.skale"
                      size="25"
                      >
                    </v-rating>
                  </div>
                </template>
                <div>
                  <div
                    v-for="(element, key) in item.rating.allowedValues"
                    :key="element">
                    <span>{{ key + ": "}}</span>
                    <span>{{ element }}</span>
                  </div>
                </div>
              </v-tooltip>
            </v-toolbar>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            
            @click="closeDialog()">
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            color="primary"
            @click="saveLanguages()">
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
   import {mapState, mapActions} from 'vuex'

   export default {
    props: {
      dialog: Boolean,
      myLanguages: Array
    },
    data () {
      return {
        myDialog:false,
        selectedRatings: [],
        selectedLanguages:[],
        initial:false
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        languages: state => Array.from(state.users.languages),
        lang: state => state.general.profilLang,
      }),
      fillSelectedList() {
        let that = this;
        if (that.myLanguages && !that.initial) {
          that.initial = true;
          for(let skill in that.myLanguages) {
            for(let item in that.languages) {
              if(that.languages[item].id === that.myLanguages[skill].id) {
                that.languages[item].level = that.myLanguages[skill].level;
                that.languages[item].userId = that.myLanguages[skill].userId;
                that.selectedLanguages.push(that.languages[item]);
                break;
              }
            }
          } 
        }
        return that.languages.sort((a, b) => a.name > b.name ? 1 : -1);
      }
    },
    filters: {
      selected(data) {
        if(data.data.level > 0 && data.selected.indexOf(data.data) < 0) {
          data.data.userId = data.userId;
          data.selected.push(data.data);
        }
        return data.selected.indexOf(data.data) > -1; 
      }
    },
    methods: {
      ...mapActions('users', ['saveMyLanguages','deleteMyLanguages']),
      closeDialog() {
        this.myDialog = this.dialog;
        this.$emit("update-expandLanguages", !this.dialog);
      },
      selectLanguage(item) {
        let index = this.selectedLanguages.indexOf(item);
        if(index < 0) {
          item.level = 1;
          item.userId = this.basicData.userID;
          this.selectedLanguages.push(item);
        } else {
          item.level = 0;
          item.userId = null;
          this.selectedLanguages.splice(index, 1)
        }
      },
      saveLanguages() {
        if(this.selectedLanguages.length > 0) {
          this.saveMyLanguages({newMyLanguages : this.selectedLanguages});
        } else {
          this.deleteMyLanguages({
            userId: this.basicData.userID
          })
        }
        this.closeDialog();
      },
      deleteSelected(item) {
        let index = this.selectedLanguages.indexOf(item);
        if(index > -1) {
          item.level = 0;
          item.userId = null;
          this.selectedLanguages.splice(index, 1)
        }
      }
    }
  }
</script>
