import { handleResponse } from "../utils/service.utils";
import { hostUrl } from '../router';

export const metaDataService = {
    getMyMetaData
};

// Meta data
function getMyMetaData() {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(hostUrl()  + `/system/meta`, requestOptions).then(handleResponse); // not sure about path
}