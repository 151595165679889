<template>
    <v-dialog
        v-model="dialog"
        v-if="dialog"
        width="800"
        persistent 
        :retain-focus="false">
        <v-card>
          <v-card-title>
            <h2>{{$t('Übersetzungen')}}</h2>
          </v-card-title>
        <v-card-text>
          <v-container>
            <v-expansion-panels>
                <v-expansion-panel v-if="translations.de">
                  <v-expansion-panel-header>DE</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form
                      ref="firstForm"
                      v-model="valid"
                    >
                      <v-row>
                        <v-col cols="9">
                          <v-text-field
                              :label="$t('profil.de.name') + '*'"
                              v-model="translations.de.name"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="translations.en">
                  <v-expansion-panel-header>EN</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form
                      ref="firstForm"
                      v-model="valid"
                    >
                      <v-row>
                        <v-col cols="9">
                          <v-text-field
                              :label="$t('profil.en.name') + '*'"
                              v-model="translations.en.name"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error"
              @click="no()"
              >
              {{$t('i18n_close')}}
            </v-btn>
            <v-btn
              color="primary"
              @click="sendTranslation()"
              >
              {{$t('i18n_save')}}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  export default {
    props: {
      dialog: Boolean
    },
    data: () => ({
      isEditable: true,
      valid: true,
    }),
    computed: {
      ...mapState({
        translations: state => state.configuration.texts
      }),
    },
    methods: {
      ...mapActions('configuration', ['saveTranslation', 'deleteTranslation']),
      no() {
        this.$emit("fire-close", false);
      },
      sendTranslation() {
        this.saveTranslation(this.translations)
        this.$emit("fire-close", false);
      }
    }
  }
</script>