<template>
  <v-card class="view print-container" >
  <v-card-text class="outer print-container">
        <v-container >
            <v-row > 
                <v-col v-if="template.displayHeader && template.headerType==='VERTICAL'" cols="4"  ref="test">
                    <v-card width="100%" height="100%" outlined :color="template.color">



                        <v-card-text>
                            <v-card :color="template.color" flat class="headerback" >
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <v-avatar
                                            tile
                                            :size="headerProfileSize" 
                                            v-if="headerProfile"                            
                                            >
                                                <v-img
                                                :src="expert.user.image"
                                                contain
                                                >
                                                </v-img>
                                            </v-avatar>
                                              <v-btn
                                                                color="white"
                                                                icon
                                                                @click="print()"
                                                                >
                                                                <v-icon>mdi-printer</v-icon>
                                                                </v-btn>
                                        </v-col>
                                        <v-col></v-col>
                                        <v-col>
                                            <v-avatar
                                            tile
                                            :size="headerLogoSize" 
                                            v-if="headerLogo"
                                            >
                                                <v-img
                                                src="../../assets/logo_aqs.png"
                                                contain
                                                >
                                                </v-img>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card :color="template.color" flat class="headerback">
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('NAME')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-account</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.firstname}} {{expert.user.lastname}}
                                            </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('DEGREE')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-school</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.degree}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('POSITION')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-briefcase</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.position}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('BIRTHDAY')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-baby-carriage</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.birthday | formatDate}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('PHONE')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-phone</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.phone}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('EMAIL')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.email}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('ADDRESS')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-home</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.address}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card :color="template.color" flat v-if="template.embedProjects" class="headerback">
                                <v-card-title>
                                    Projekte
                                </v-card-title>
                                <v-card-text>
                                    <v-list dense disabled :color="template.color">
                                        <v-list-item v-for="project in expert.projects" :key="project.id">
                                            <v-list-item-title>
                                                {{project.customer}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{project.start | formatDate}} - {{project.end | formatDate}}
                                            </v-list-item-subtitle>
                                            <v-list-item-content>
                                                  {{project.role}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider/>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                            <v-card :color="template.color" flat v-if="template.embedExperience" class="headerback">
                                <v-card-title>
                                    Berufserfahrung
                                </v-card-title>
                                <v-card-text>
                                    <v-list dense disabled :color="template.color">
                                        <v-list-item v-for="experience in expert.experience" :key="experience.id">
                                            <v-list-item-title>
                                                {{experience.organization}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{experience.start | formatDate}} - {{experience.end | formatDate}}
                                            </v-list-item-subtitle>
                                            <v-list-item-content>
                                                  {{experience.role}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider/>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                            <v-card :color="template.color" flat v-if="template.embedEducation" class="headerback">
                                <v-card-title>
                                    Bildung
                                </v-card-title>
                                <v-card-text>
                                    <v-list dense disabled :color="template.color">
                                        <v-list-item v-for="education in expert.studies" :key="education.id">
                                            <v-list-item-title>
                                                {{education.school}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{education.start | formatDate}} - {{education.end | formatDate}}
                                            </v-list-item-subtitle>
                                        </v-list-item>
                                        <v-divider/>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                            <v-card :color="template.color" flat v-if="template.embedCertificate" class="headerback">
                                <v-card-title>
                                    Zertifikate
                                </v-card-title>
                                <v-card-text>
                                    <v-list dense disabled :color="template.color">
                                        <v-list-item v-for="certificate in expert.certificates" :key="certificate.id">
                                            <v-list-item-title>
                                                {{certificate.name}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{certificate.dateIssued | formatDate}} 
                                            </v-list-item-subtitle>
                                        </v-list-item>
                                        <v-divider/>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                    
              </v-col>

              <v-col ref="test2">
            
            <v-row v-for="module in modules" :key="module.name" class="print-section">
                <v-col >
                    <div :ref="module.name">
                <v-card v-if="module.id===6 && module.active && template.headerType==='HORIZONTAL'"  width="100%" outlined :color="template.color" >
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    class="no-print-action"
                >
                <v-spacer></v-spacer>
                    <v-btn
                    color="white"
                    icon
                    @click="print()"
                    >
                    <v-icon>mdi-printer</v-icon>
                    </v-btn>
                </v-app-bar>
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-avatar :size="headerProfileSize" tile v-if="headerProfile">
                                    <v-img  :src="expert.user.image" contain ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-row>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('NAME')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-account</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.firstname}} {{expert.user.lastname}}
                                            </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('DEGREE')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-school</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.degree}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('POSITION')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-briefcase</v-icon>
                                             </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.position}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('BIRTHDAY')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-baby-carriage</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.birthday | formatDate}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('PHONE')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-phone</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.phone}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('EMAIL')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.email}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                        <v-col>
                                            <v-list-item v-if="template.headerFields && template.headerFields.includes('ADDRESS')">
                                            <v-list-item-icon>
                                                <v-icon>mdi-home</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                {{expert.user.address}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                                <v-list-item-avatar :size="headerLogoSize" tile v-if="headerLogo">
                                    <v-img  src="../../assets/logo_aqs.png" contain ></v-img>
                                </v-list-item-avatar>
                            </v-list-item>
                        </v-card-text>
                </v-card>
                <v-card class="profile" flat v-if="module.id===0 && module.active" >
                    <v-card-title>
                        <template v-if="logo">
                            <v-spacer></v-spacer>
                            <v-img src="../../assets/logo_aqs.png" contain apsect-ration="1.7" :max-height="logoHeight" :max-width="logo-Width"></v-img>
                            <v-spacer></v-spacer>
                        </template>
                        
                    </v-card-title>
                    <v-card-text>
                        <VueMarkdown :source="template.template"></VueMarkdown>
                    </v-card-text>
                </v-card>
                <v-card class="profile" flat  >

                </v-card>
                <general-information v-if="module.id===1 && module.active" :expert="expert" :template="template">
                </general-information>
                <skills  v-if="module.id===2 && module.active" :expert="expert" :template="template" :skills="skills" :classifiedSkills="classifiedSkills">
                </skills>

               
                <experience :expert="expert" :template="template" v-if="module.id===3 && module.active" ></experience >
                <project :expert="expert" :template="template" v-if="module.id===7 && module.active" ></project>
                <education :expert="expert" :template="template" v-if="module.id===4 && module.active" ></education>
                <certificates :expert="expert" :template="template" v-if="module.id===5 && module.active" ></certificates>

                <!--<v-card class="profile" flat v-if="module.id===3 && module.active">
                    <v-card-title>
                        Berufserfahrung
                    </v-card-title>
                    <v-card-text>
                        <Experience :editable="false"></Experience>
                    </v-card-text>
                </v-card>
                <v-card class="profile" flat v-if="module.id===4 && module.active">
                    <v-card-title>
                        Bildung
                    </v-card-title>
                    <v-card-text>
                        <Education :editable="false"></Education>
                    </v-card-text>
                </v-card>-->
                <!--<v-card class="profile" flat v-if="module.id===5 && module.active">
                    <v-card-title>
                        Zertifikate
                    </v-card-title>
                    <v-card-text>
                        <Certificates :editable="false"></Certificates>
                    </v-card-text>
                </v-card>-->
                    </div>
                </v-col>
            </v-row>
            </v-col>
            
            </v-row>
            
        </v-container>
    </v-card-text>
    <v-card-actions class="no-print-action">
        <v-spacer></v-spacer>
        <!-- TODO download pdf-->
        <v-btn v-if="false" v-on:click="downloadPDF(modules,template)">Download</v-btn>
        <router-link to="/">{{$t('i18n_cancel')}}</router-link>
    </v-card-actions>
  </v-card>
</template>
 
<script>
    import VueMarkdown from 'vue-markdown';
    //import UserInfo from './UserInfo.vue';
    //import ChipsWithTooltips from './ChipsWithTooltips';
    import Education from './templates/Education';
    import Certificates from './templates/Certificates';
    import Skills from './templates/Skills.vue'
    import GeneralInformation from './templates/GeneralInformation.vue'
    import Experience from './templates/Experience'
    import Project from './templates/Project'
    //import {createPDF} from '../../services/pdf.generation.service'
    import jsPDF from 'jspdf'
    
    import html2canvas from "html2canvas"
    

    export default{
        data:() => ({
            modules : [],
        }),
        props:{
            expert: Object,
            template: Object,
            skills: Array,
            classifiedSkills: Array
        },
        components:{
            VueMarkdown,
            //UserInfo,
           // ChipsWithTooltips,
            Experience,
            Education,
            Certificates,
            GeneralInformation,
            Project,
            Skills,
            
        },
        methods: {
            print(){
                window.print();
            },
            downloadPDF(modules, template) {
                var doc = new jsPDF('p','mm');
                var pageHeight= doc.internal.pageSize.height;

                var nextOffset = 0;

                var refs = this.$refs;
                var pdfEle = this.$refs.test;

                //var pageheight = 297;
                //var pagewidth = 210;
                var contentoffset = 0;
                var contentwidth = 210;
                if(template.displayHeader && template.headerType==='VERTICAL'){
                    contentoffset = 70;
                    contentwidth = 140
                    //Left side
                  html2canvas(pdfEle,{useCORS: true,scrollX :0,scrollY : 0}).then(function(canvas){
                      var img=canvas.toDataURL("image/png");
                      
                      doc.addImage(img,'JPEG',0,0,70,0);
                  });
                } 
                
                // Right side
                modules.forEach(function(element) {
                    var toAdd = refs[element.name];
                    html2canvas(toAdd[0],{useCORS: true,scrollX :0,scrollY : -window.scrollY}).then(function(canvas){
                        var img=canvas.toDataURL("image/png");
                        var factor = contentwidth / canvas.width;
                        var height = canvas.height * factor;
                        // Before adding new content
                        if (nextOffset >= pageHeight){
                          doc.addPage();
                          nextOffset = 0 // Restart height position
                        }
                        doc.addImage(img,'JPEG',contentoffset,nextOffset,contentwidth,height);
                        nextOffset += height + nextOffset;
                    });
                });

                html2canvas(refs.test2,{useCORS: true,scrollX :0,scrollY : -window.scrollY}).then(function(){
                    //var img=canvas.toDataURL("image/png");
                    
                  //  doc.addImage(img,'JPEG',70,0,140,297);
                    doc.save(template.name);
                });
                
                }
        },
        created(){
            this.modules = JSON.parse(this.template.modulesOrder);
        },
        filters : {
                formatDate(date) {
                    if(date) {
                        return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
                    }
                }
        },
        computed: {
            headerProfile(){
                if(this.template.headerProfileType){
                    return this.template.headerProfileType !== 'NONE';
                }
                return false;
            },
            logo(){
                if(this.template.logoType){
                    return this.template.logoType !== 'NONE';
                }
                return false;
            },
            headerLogo(){
                if(this.template.headerLogoType){
                    return this.template.headerLogoType !== 'NONE';
                }
                return 0;
                
            },
            headerLogoHeight(){
                if(this.template.headerLogoType && this.template.headerLogoType === 'SMALL'){
                    return 50;
                }
                if(this.template.headerLogoType && this.template.headerLogoType === 'MEDIUM'){
                    return 100;
                }
                if(this.template.headerLogoType && this.template.headerLogoType === 'LARGE'){
                    return 200;
                }
                return 0;
            },
            headerLogoSize(){
                if(this.template.headerLogoType && this.template.headerLogoType === 'NONE'){
                    return 0;
                }else if(this.template.headerLogoType && this.template.proheaderLogoTypeileType === 'SMALL'){
                    return 64;
                }else if(this.template.headerLogoType && this.template.headerLogoType === 'MEDIUM'){
                    return 128;
                }else if(this.template.headerLogoType && this.template.headerLogoType === 'LARGE'){
                    return 256;
                }else{
                    return 128;
                }
            },
            headerProfileSize(){
                if(this.template.profileType && this.template.profileType === 'NONE'){
                    return 0;
                }else if(this.template.profileType && this.template.profileType === 'SMALL'){
                    return 64;
                }else if(this.template.profileType && this.template.profileType === 'MEDIUM'){
                    return 128;
                }else if(this.template.profileType && this.template.profileType === 'LARGE'){
                    return 256;
                }else{
                    return 128;
                }
            },
            logoHeight(){
                if(this.template.logoType && this.template.logoType === 'SMALL'){
                    return 50;
                }
                if(this.template.logoType && this.template.logoType === 'MEDIUM'){
                    return 100;
                }
                if(this.template.logoType && this.template.logoType === 'LARGE'){
                    return 200;
                }
                return 0;
            },
            headerLogoWidth(){
                if(this.template.headerLogoType && this.template.headerLogoType === 'SMALL'){
                    return 50;
                }
                if(this.template.headerLogoType && this.template.headerLogoType === 'MEDIUM'){
                    return 100;
                }
                if(this.template.headerLogoType && this.template.headerLogoType === 'LARGE'){
                    return 200;
                }
                return 0;
            },
            headerProfileWidth(){
                if(this.template.headerProfileType && this.template.headerProfileType === 'SMALL'){
                    return 50;
                }
                if(this.template.headerProfileType && this.template.headerProfileType === 'MEDIUM'){
                    return 100;
                }
                if(this.template.headerProfileType && this.template.headerProfileType === 'LARGE'){
                    return 200;
                }
                return 0;
            },
            logoWidth(){
                if(this.template.logoType && this.template.logoType === 'SMALL'){
                    return 50;
                }
                if(this.template.logoType && this.template.logoType === 'MEDIUM'){
                    return 100;
                }
                if(this.template.logoType && this.template.logoType === 'LARGE'){
                    return 200;
                }
                return 0;
            },
            
        }

    }
</script>

<style scoped>
.profile {
    width: 100%;
    background-color: white;
}
.view {
    width: 100%;
    
}
#canvas_handler {
   position:absolute;
   left: -10000px;
}
.v-application--wrap{
    background-color: white;
}
.container {
    background-color: white;
}
.profile .v-card__text{
    background-color: white;
}
.profile .v-card__title{
    background-color: white;
}
.profile .v-card__subtitle{
    background-color: white;
}
.headerback .v-card__text {
    background-color:rgba(0, 0, 0, 0);
}
.headerback .v-card__title {
    background-color:rgba(0, 0, 0, 0);
}
.outer{
    background-color: white;
}
.headerback .v-list-item {
  flex-wrap: wrap;
}
.headerback .v-list-item__title {
  white-space: normal;
}
  @media print{
  .display-no{
  display:none;
  }
        body{
        width:100%;
      height: 100%;
       }
       @page{
        size: A4;
        margin:0px;
        padding:0px;
       }
       #print{
       margin-top:20px;
       }
       .print-section, .headerback{
        break-inside: avoid;
       }
        .gridCard{
       page-break-inside: avoid;
         visibility: visible;
        height:auto!important;
        margin-bottom:17%;

           overflow-y: hidden;
         }
         .v-sheet.v-app-bar.v-toolbar{
         height:0px!important;
         }
         .v-main{
         padding:0px;
         }
      body * {
        visibility: hidden;
      }
      .print-container , .print-container * {
        visibility: visible;
      }
      /** exclude button */
      .no-print-action  , .no-print-action *{
        visibility: hidden;
      }

      #title_id {
        visibility: visible;
      }
      .print-container {
        top: 0px !important;
        left: 0px !important;
      } 
      .print-pr{
         margin-top:25%;
      }
  }

 
</style>
