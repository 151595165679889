<template>
    <v-content>
        <template v-if="loggedIn">
            <div class="text-center" v-if="loading">
                <v-progress-circular
                        size="100"
                        indeterminate>
                </v-progress-circular>
            </div>
            <print-view v-else :template="publication.template" :expert="publication.expert" :skills="publication.expert.simpleClassification" :classifiedSkills="publication.expert.classifiedSkills" :modules="modules"></print-view>    
        </template>
        <template v-else>
            <publication-login @publicationLogin="makeLogin($event)"></publication-login>
        </template>
</v-content>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isLoggedIn } from '../router';
import PrintView from '../components/customItems/PrintView.vue'
import PublicationLogin from '../components/customItems/PublicationLogin'
import jsPDF from 'jspdf'
import html2canvas from "html2canvas"
 export default {
   data: () => ({
        modules: [
            {id: 6, name: "Überschrift",active: true},
            {id: 0, name: "Einführungstext", active: true},
            {id: 1, name: "Generelle Informationen", active: true},
            {id: 2, name: "Skills", active: true},
            {id: 3, name: "Bildung",active: true},
            {id: 4, name: "Berufserfahrung", active: true},
            {id: 7, name: "Projekte", active: true},
            {id: 5, name: "Zertifikate", active: true},
            ],
        loading: true,
        loggedIn: false,
    }),
  computed: {
       ...mapState({
            publication: state => state.publication.publication,
        }),
        templateName(){
            return this.publication.template.name
        },
  },
  methods: {
      ...mapActions('publication', ['publicationData','publicationLogin']),
      makeLogin(password){
          this.publicationLogin({username:this.$route.params.token,password:password});
          //this.publicationData(this.$route.params.token)
          this.loggedIn = true;
          
      },
      downloadPDF(template) {
                html2canvas(document.body).then(function(canvas){
                    var img=canvas.toDataURL("image/png");
                    var doc = new jsPDF('p','mm');
                    doc.addImage(img,'JPEG',0,0);
                    doc.save(template.name);
                    });
                
                }
  },
  components: {
      PrintView,
      PublicationLogin
  },

  created(){
      if(isLoggedIn()){
          this.loggedIn = true;
          this.publicationData(this.$route.params.token);
      }
  },
  watch: {
      publication: {
          deep: true,
          handler: function(){
              this.loading = false;
          }
      }
  }
}
</script>
<style scoped>
.v-sheet.v-app-bar.v-toolbar{
height:0px!important;
}
.view {
    max-width : 1300px;
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0px;
}
.test {
    height: fill-content;
    width: fill-content;
}
.container {
    height: 100%;
}
.content {
    height: 100%;
    width: 100%;
}

</style>

