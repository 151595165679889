<template>
  <div class="test" v-if="visible">
    <v-list-item-title>{{title}}</v-list-item-title>
      <v-layout>
        <slot/>
      </v-layout>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    props: ['title','visible'],
    data: () => ({
      expanded:true
    }),
    computed: {
        ...mapState({
          expandAll: state => state.general.expandAll.expand,
          switch: state => state.general.expandAll.switch
        }),
        educationIconChange: function() {
            return this.expanded ? 'mdi-chevron-up':'mdi-chevron-down';
        }
    },
    watch: {
        switch() {
          this.expanded =this.expandAll;
        }
    },
    methods: {
      onExpand() {
        this.expanded = !this.expanded
      }
    }
  }
</script>

<style>
  .expandBottom {
    height: 100%;
    padding-bottom: 12px;
  }

</style>
