<template>
  <v-list dense subheader>
    <v-col v-if="classifiedSkill.userSkills.length !== 0
    || classifiedSkill.offsprings.length !== 0">
      
      <div v-for="skill in classifiedSkill.userSkills" :key="skill.skillId">
          <skill  :item="skill" :text="text" :chip="chip" :level="level" :colored="colored"></skill>
      </div>
      <div v-for="subItem in classifiedSkill.offsprings"  v-bind:key="subItem.classification">
        <CustomSkillTree
          :title="subItem.name"
          :visible="hasChildOnLeaf(subItem)"
          class="subcontent">
          <skill-hierarchy :classifiedSkill="subItem" :text="text" :chip="chip" :level="level" :colored="colored"/>
        </CustomSkillTree>
      </div>
    </v-col>
  </v-list>
</template>
 
<script>
    //import ChipsWithTooltips from '../ChipsWithTooltips'
    import CustomSkillTree from '../CustomSkillTree.vue';
    import Skill from './Skill'
    import { sortSkillsByLevel } from "../../../utils/profilePage.utils";
    export default{
        name : "skill-hierarchy",
        data: () => ({
            
        }),
        props:{
            classifiedSkill: Object,
            item: Object,
            chip: Boolean,
            text: Boolean,
            colored: Boolean,
            level: Boolean
        },
        components:{
            CustomSkillTree,
            Skill,
            //ChipsWithTooltips
        },
        computed: {

        },
        methods: {
            hasChildOnLeaf(item){
                if(Array.isArray(item.offsprings) && item.offsprings.length !== 0){
                return this.hasChildOnLeaf(item.offsprings[0]);
                } else {
                return Array.isArray(item.userSkills) && item.userSkills.length !== 0;
                }
            },
            sortSkillsByLevel
        }
    }
</script>

<style scoped>
.profile {
    width: 100%;
}   
.profile .v-card__text{
    background-color: white;
}
.profile .v-card__title{
    background-color: white;
}
.v-list-item {
    background-color: white;
}
.profile .v-card__subtitle{
    background-color: white;
}
</style>
