<template>
    <v-card class="profile" flat>
        <v-card-title>
            Projekte
        </v-card-title>
        <v-card-text>
                    <v-simple-table v-if="table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Kunde</th>
                                    <th>Rolle</th>
                                    <th>Start</th>
                                    <th>Ende</th>
                                    <th v-if="description">Beschreibung</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="project in projects" :key="project.id" class="print-section">
                                    <td>{{project.customer}}</td>
                                    <td>{{project.role}}</td>
                                    <td>{{project.start | formatDate}}</td>
                                    <td>{{project.end | formatDate}}</td>
                                    <td v-if="description">
                                        <template v-if="formatted">
                                            <VueMarkdown :source="project.description"></VueMarkdown>
                                        </template>
                                        <template v-else>
                                            {{project.description}}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-container  v-else-if="timeline">
                                <v-timeline dense>
                                    <v-timeline-item
                                    v-for="project in projects" :key="project.id"
                                    small
                                    :color="template.color"
                                    class="print-section"
                                    >
                                    
                                    <v-card>
                                        <v-card-title>
                                            {{project.customer}}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{project.role}} {{project.start | formatDate}} - {{project.end | formatDate}}
                                        </v-card-subtitle>
                                        <v-card-text>
                                            {{project.description}}
                                        </v-card-text>
                                    </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                                
                    </v-container>
                    <v-list dense v-else>
                        <template v-for="(item, index) in projects">
                        <v-list-item class="showhim print-section"
                            :key="item.id">
                            <v-list-item-icon>
                                <v-icon>mdi-briefcase</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                            <h3 style="margin-bottom: 5px;">{{item.customer}}</h3>
                            <v-list-item-subtitle>{{item.role}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="margin-bottom: 15px;">{{item.start | formatDate}} {{ item.end ? '-' : ''}} {{item.end | formatDate}}</v-list-item-subtitle>
                            <template v-if="description">
                                <div v-if="formatted">
                                    <VueMarkdown :source="item.description"></VueMarkdown>
                                </div>
                                <div v-else>
                                    {{item.description}}
                                </div>
                            </template>
                            
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            v-if="index + 1 < projects.length"
                            :key="`${index}-divider`">
                        </v-divider>
                        </template>
                    </v-list>
        </v-card-text>

    </v-card>
</template>
 
<script>
    //import { mapState } from "vuex";
    import VueMarkdown from 'vue-markdown';
    export default{
        data: () => ({
            
        }),
        props:{
            expert: Object,
            template: Object
        },
        computed: {
            //...mapState({
            //experiences: state => state.users.experience.sort(function(a,b){
            //    return (new Date(a.end) - new Date(b.end)) * -1;
            //    })
            //}),
            projects(){
                return this.expert.projects
            },
            table(){
                return this.template.projectsLayout === 'TABLE';
            },
            timeline(){
                return this.template.projectsLayout === 'TIMELINE';
            },
            compact(){
                 return this.template.projectsLayout === 'COMPACT';
            },
            description(){
                return this.template.projectsDescription !== 'NONE';
            },
            formatted(){
                return this.template.projectsDescription === 'FORMATTED_TEXT'
            }
        },
        filters : {
            formatDate(date) {
                if(date) {
                    return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
                }
            }
        },
        components : {
            VueMarkdown
        }
    }
</script>

<style scoped>
.profile {
    width: 100%;
}
@media print {
    .print-section {
        break-inside: avoid;
    }

}
.v-timeline-item{
 page-break-inside: avoid;
   visibility: visible;
  height:auto!important;

     overflow-y: hidden;
}
.profile {
    page-break-inside: avoid;
    visibility: visible;
    height: auto!important;
    overflow-y: hidden;
}

</style>
