import { roleService } from '../services';

const state = {
    all: {},
    roles: [],
    roleUser:[],
    privileges: {}
};

const actions = {
    getAllRoles({ commit }) {
        commit('setLoading', true);

        roleService.getAllRoles()
            .then(
                users => {
                    commit('getAllRolesSuccess', users);
                    commit('setLoading', false);
                },
                error => {
                    commit('getFailure', error);
                    commit('setLoading', false);
                }
            );
    },
    getAllUserRoles({ commit }) {
        commit('setLoading', true);

        roleService.getAllUserRoles()
            .then(
                users => {
                    commit('getAllUserRolesSuccess', users);
                    commit('setLoading', false);
                },
                error => {
                    commit('getFailure', error);
                    commit('setLoading', false);
                }
            );
    },
    getAllPrivileges({ commit }) {
        commit('setLoading', true);

        roleService.getAllPrivileges()
            .then(
                users => {
                    commit('getAllPrivilegesSuccess', users);
                    commit('setLoading', false);
                },
                error => {
                    commit('getFailure', error);
                    commit('setLoading', false);
                }
            );
    },
    saveRole({ dispatch, commit }, role) {
        roleService.saveRole(role)
            .then(
                data => {
                    commit('saveRoleSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully saved role!', { root: true });
                    })
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/success', 'Saving role failed!', { root: true });
                }
            );
    },
    deleteRole({ dispatch, commit }, id) {
        roleService.deleteRole(id)
            .then(
                () => {
                    commit('deleteRoleSuccess', id);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully deleted role!', { root: true });
                    })
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/success', 'delete role failed!', { root: true });
                }
            );
    },
    saveRoleUser({ dispatch, commit }, roleUser) {
        roleService.saveRoleUser(roleUser)
            .then(
                data => {
                    commit('getAllUserRolesSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully saved role-user!', { root: true });
                    })
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/success', 'Saving role-user failed!', { root: true });
                }
            );
    },
    deleteRoleUser({ dispatch, commit }, id) {
        roleService.deleteRoleUser(id)
            .then(
                () => {
                    commit('deleteRoleUserSuccess', id);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully deleted role-user!', { root: true });
                    })
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/success', 'delete role-user failed!', { root: true });
                }
            );
    }
};

const mutations = {
    setLoading  (state, payload) {
        state.all = { loading: payload };
    },
    getAllRolesSuccess(state, roles) {
        state.roles = roles;
    },
    getAllUserRolesSuccess(state, roleUser) {
        state.roleUser = roleUser;
    },
    getFailure(state, error) {
        state.all = { error };
    },
    getAllPrivilegesSuccess(state, privileges) {
        state.privileges = privileges;
    },
    saveRoleSuccess(state, roles) {
        state.roles = roles;
    },
    deleteRoleUserSuccess (state, id) {
        state.roleUser = state.roleUser.filter(roleUser => roleUser.id !== id)
    },
    deleteRoleSuccess (state, id) {
        state.roles = state.roles.filter(role => role.id !== id)
    }
};

export const rolesAndPrivileges = {
  namespaced: true,
  state,
  actions,
  mutations
};
