import { configurationService } from '../services';

const state = {
  renderTable:true,
  skills:[],
  classifications:[],
  language:[],
  rating:[],
  loading:true,
  texts:[]
};

const actions = {
  getConfiguration({ commit }, code) {
    commit('getAllRequest');
    commit('setLoading', true);
    
    configurationService.getConfiguration(code)
      .then(
        configuration => {
          commit('getConfigurationSuccess', configuration);
          commit('setLoading', false);
        },
        error => {
          commit('requestFailure', error);
          commit('setLoading', false);
        }
      );
  },
  saveClassifications({ commit }, data) {
    commit('getAllRequest');
    configurationService.saveClassifications(data)
      .then(
        classification => commit('saveClassificationsSuccess', classification),
        error => commit('requestFailure', error)
      );
  },
  deleteClassification({ dispatch, commit }, data) {
    commit('getAllRequest');

    configurationService.deleteClassification(data)
      .then(
        classification => {
          commit('saveClassificationsSuccess', classification);
          setTimeout(() => {
              dispatch('alert/success', 'Succesfully deleted classification!', { root: true });
          });
        },
        error => {
            commit('requestFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
  },
  saveLanguage({ commit }, data) {
    commit('getAllRequest');

    configurationService.saveLanguage(data)
      .then(
        language => commit('saveLanguageSuccess', language),
        error => commit('requestFailure', error)
      );
  },
  deleteLanguage({ dispatch, commit }, data) {
    commit('getAllRequest');

    configurationService.deleteLanguage(data)
      .then(
        language => {
          commit('saveLanguageSuccess', language);
          setTimeout(() => {
              dispatch('alert/success', 'Succesfully deleted language!', { root: true });
          });
        },
        error => {
            commit('requestFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
  },
  saveRating({ commit }, data) {
    commit('getAllRequest');

    configurationService.saveRating(data)
      .then(
        rating => commit('saveRatingSuccess', rating),
        error => commit('requestFailure', error)
      );
  },
  deleteRating({ dispatch, commit }, data) {
    commit('getAllRequest');

    configurationService.deleteRating(data)
      .then(
        rating => {
          commit('saveRatingSuccess', rating);
          setTimeout(() => {
              dispatch('alert/success', 'Succesfully deleted rating!', { root: true });
          });
        },
        error => {
            commit('requestFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
  },
  saveSkill({ commit }, data) {
    commit('getAllRequest');

    configurationService.saveSkill(data)
      .then(
        skill => commit('saveSkillSuccess', skill),
        error => commit('requestFailure', error)
      );
  },
  deleteSkill({  dispatch, commit }, data) {
    commit('getAllRequest');

    configurationService.deleteSkill(data)
      .then(
        skill => {
          commit('saveSkillSuccess', skill);
          setTimeout(() => {
              dispatch('alert/success', 'Succesfully saved skill!', { root: true });
          });
        },
        error => {
            commit('requestFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
  },
  getTexts({ commit }, data) {
    commit('setLoading', true);
    commit('getTextsSuccess',[]);
    configurationService.getTexts(data)
        .then(
          texts => {
                commit('getTextsSuccess', texts);
                commit('setLoading', false);
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
  },
  saveTranslation({ dispatch, commit }, data) {
    commit('getAllRequest');
    configurationService.saveTranslation(data)
      .then(
        texts => {
          commit('getTextsSuccess', texts);
          dispatch('alert/success', 'Succesfully saved translation!', { root: true });
        },
        error => {
          commit('requestFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      );
  },
  deleteTranslation({ commit }, data) {
    commit('getAllRequest');
    configurationService.deleteTranslation(data)
      .then(
        () => commit('deleteTranslationSuccess', data.elementParentId),
        error => commit('requestFailure', error)
      );
  },
};

const mutations = {
  setLoading(state, payload) {
    state.loading = payload ;
  },
  getAllRequest(state) {
    state.renderTable = false;
  },

  getConfigurationSuccess(state, configuration) {
    state.skills = configuration.skillsConfigDto;
    state.classifications = configuration.classifications;
    state.rating = configuration.rating;
    state.language = configuration.language;
    state.renderTable = true;
  },
  saveClassificationsSuccess(state, classification) {
    state.classifications = classification;
    state.renderTable = true;
  },
  saveLanguageSuccess(state, language) {
    state.language = language;
    state.renderTable = true;
  },
  saveRatingSuccess(state, rating) {
    state.rating = rating;
    state.renderTable = true;
  },
  saveSkillSuccess(state, skills) {
    state.skills = skills;
    state.renderTable = true;
  },
  requestFailure(state, error) {
    state.all = { error };
  },
  getTextsSuccess(state, texts) {
    state.texts = texts;
  },
  deleteTranslationSuccess(state, id) {
    // remove deleted user from state
    if(state.texts.texts) {
      state.texts.texts = state.texts.texts.filter(text => text.id !== id);
    } else {
      state.texts = state.texts.filter(text => text.id !== id)
    }
  },
};

export const configuration = {
  namespaced: true,
  state,
  actions,
  mutations
};
