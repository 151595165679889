<template>
    <v-card class="profile" flat>
        <v-card-title>
            Zertifikate
        </v-card-title>
        <v-card-text>
            <v-simple-table v-if="table">
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="certificate in certificates" :key="certificate.id" class="print-section">
                                    <td>{{certificate.name}}</td>
                                    <td>{{certificate | validDate}}</td>
                                    <td v-if="description">
                                        <template v-if="formatted">
                                            <VueMarkdown :source="certificate.description"></VueMarkdown>
                                        </template>
                                        <template v-else>
                                            {{certificate.description}}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-list dense v-else>
                        <template v-for="(item, index) in certificates">
                            <v-list-item class="showhim print-section"
                                :key="item.skill">
                                <v-list-item-icon>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                <v-list-item-subtitle class="text--primary">{{item | validDate}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                                v-if="index + 1 < certificates.length"
                                :key="index"
                            ></v-divider>
                            </template>
                    </v-list>
        </v-card-text>

    </v-card>
</template>
 
<script>
    //import { mapState } from "vuex";
    import VueMarkdown from 'vue-markdown';
    export default{
        data: () => ({
            
        }),
        props:{
            expert: Object,
            template: Object
        },
        computed: {
            certificates(){
                return this.expert.certificates
            },
            table(){
                return this.template.certificateLayout === 'TABLE';
            },
            compact(){
                 return this.template.certificateLayout === 'COMPACT';
            },
            description(){
                return this.template.certificationDescription !== 'NONE';
            },
            formatted(){
                return this.template.certificationDescription === 'FORMATTED_TEXT'
            }
        },
        filters : {
            formatDate(date) {
                if(date) {
                    return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
                }
            },
            validDate (item) {
                return item.dateIssued !== null || item.dateIssued !== ""
                        ? new Date(item.dateIssued).getMonth() + 1 + "/" + new Date(item.dateIssued).getUTCFullYear()
                        : "";
            }
        },
        components : {
            VueMarkdown
        }
    }
</script>

<style scoped>
.profile {
    width: 100%;
}   
</style>
