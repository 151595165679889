<template>
    <v-card class="profile" flat>
        <v-card-title>
            Skills
        </v-card-title>
        <v-card-text v-if="compact">
        <template v-for="(item,index) in skills" >
            <v-list-item  :key="index">
                <v-list-item-content>
                    <v-list-item-title>
                        {{item.classification.name}}
                    </v-list-item-title>
                    <v-row>
                        <v-col cols="3"></v-col>
                        <v-col>
                            <v-row><skill :key="skill.name" v-for="skill in item.skills" :item="skill" :text="!chips" :chip="chips" :level="levels" :colored="colors"></skill></v-row>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
        </template>
        </v-card-text>
        <v-card-text v-else-if="table">
            <v-simple-table disabled>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Kategorie</th>
                            <th>Skills</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item,index) in skills" >
                            <tr :key="index">
                                <td>{{item.classification.name}}</td>
                                <td>
                                    <v-row class="skill_row">
                                        <skill :key="skill.name" v-for="skill in item.skills" :item="skill" :text="!chips" :chip="chips" :level="levels" :colored="colors"></skill>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-text v-else>
            <template v-for="(item,index) in classifiedSkills" >
                <v-list-item  :key="index">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{item.name}}
                        </v-list-item-title>
                        <v-row>
                            <skill-hierarchy :classifiedSkill="item" :text="!chips" :chip="chips" :level="levels" :colored="colors">
                            </skill-hierarchy>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-card-text>
    </v-card>
</template>
 
<script>
    //import ChipsWithTooltips from '../ChipsWithTooltips'
    import SkillHierarchy from './SkillHierarchy.vue'
    import Skill from './Skill'
    export default{
        data: () => ({
            
        }),
        props:{
            expert: Object,
            template: Object,
            skills: Array,
            classifiedSkills: Array
        },
        components:{
            //ChipsWithTooltips
            SkillHierarchy,
            Skill
        },
        computed: {
            compact(){
                return this.template.skillLayout === 'COMPACT';
            },
            table(){
                return this.template.skillLayout === 'TABLE';
            },
            levels(){
                return this.template.skillLevels !== 'NONE';
            },
            colors(){
                return this.template.skillLevels === 'COLOR';
            },
            chips(){
                return this.template.skillDisplay === 'CHIPS'
            }
        },
    }
</script>

<style scoped>
.profile {
    width: 100%;
    
} 
.profile {
    width: 100%;
}   
.profile .v-card__text{
    background-color: white;
}
.profile .v-card__title{
    background-color: white;
}
.v-list-item {
    background-color: white;
}
.profile .v-card__subtitle{
    background-color: white;
}  
.profile .skill_row {
  margin: 0px;
  padding: 3px;
}
</style>
