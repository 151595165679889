<template >
  <v-data-table
    :headers="languageHeader"
    :items="languages"
    :search="search"
    item-key="Language">
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{$t('i18n_languages')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('i18n_search')"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <DeleteWarning :dialog="deleteDialog" @fire-no="cancelDelete" @fire-yes="sendDelete"/>
        <TranslateLanguage :dialog="translateDialog" @fire-close="closeTranslate"/>
        <EditConfigLanguages :toEdit="newLanguage" :dialog="dialog" @fire-save="save" @fire-close="initEdit"/>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pen
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        small
        @click="translateItem(item)"
      >
        mdi-translate
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  import EditConfigLanguages from '../customDialogs/EditConfigLanguages.vue';
  import DeleteWarning from '../customDialogs/DeleteWarning.vue';
  import TranslateLanguage from '../customDialogs/translate/TranslateLanguage.vue';
 
  export default {
    data: () => ({
      currentItem:null,
      dialog:false,
      deleteDialog:false,
      translateDialog:false,
      search:"",
      newLanguage: {
        id:null,
        name:null,
        code:null,
        relevance:1,
        rating:""
      }
    }),
    computed: {
      ...mapState({
        languages: state => state.configuration.language,
        isEditable: state => state.general.selectedPageEditable
      }),
      languageHeader() {
        var result = [
          {
            text: this.$t('i18n_languages'),
            align: 'left',
            sortable: false,
            value: 'name',
          },
          {
            text: this.$t('i18n_conf_relevance'),
            value: 'relevance',
          },
          { text: this.$t('i18n_conf_rating'), value: 'rating.skaleName' }
        ];
        if(this.isEditable) {
          result.push({ text: this.$t('i18n_actions'), value: 'action', sortable: false });
        }
        return result;
      },
    },
    methods: {
      ...mapActions('configuration', ['saveLanguage', 'deleteLanguage', 'getTexts']),
      save(toSave) {
        this.saveLanguage(toSave);
        this.initEdit(false);
      },
      editItem (item) {
        this.dialog = true
        this.newLanguage = Object.assign({}, item);
      },
      translateItem (item) {
        this.translateDialog = true;
        this.getTexts({tableName: "language", id: item.id});
      },
      closeTranslate() {
        this.languageId = null;
        this.translateDialog = false;
      },
      deleteItem (item) {
        this.currentItem = item;
        this.deleteDialog = true;
      },
      sendDelete() {
        this.deleteLanguage(this.currentItem);
        this.deleteDialog = false;
      },
      cancelDelete() {
        this.currentItem = null;
        this.deleteDialog = false;
      },
      initEdit(isOpen) {
        this.newLanguage = {
          id:null,
          name:null,
          relevanz:1,
          code: this.$i18n.locale,
          rating:""
        }
        this.dialog = isOpen;
      }
    },
    components: {
        EditConfigLanguages,
        DeleteWarning,
        TranslateLanguage
    }
  }
</script>
