import { searchExpertService } from '../services';

const state = {
    all: {},
    skills: []
};

const actions = {
  getAll({ commit }, code) {
      commit('setLoading', true);

      searchExpertService.getAll(code)
          .then(
              experts => {
                  commit('getAllSuccess', experts);
                  commit('setLoading', false);
              },
              error => {
                  commit('getAllFailure', error);
                  commit('setLoading', false);
              }
          );
  },
  getSkills({ commit }, code) {

    searchExpertService.getSkills(code)
        .then(
            skills => {
                commit('getSkillsSuccess', skills);
                commit('setLoading', false);
            },
            error => {
                commit('getAllFailure', error);
                commit('setLoading', false);
            }
        );
  },
  searchExperts({ commit }, advancedSearch) {
    searchExpertService.searchExperts(advancedSearch)
        .then(
          experts => {
            commit('getAllSuccess', experts);
            commit('setLoading', false);
          },
          error => {
              commit('getAllFailure', error);
              commit('setLoading', false);
          }
        )
  }
};

const mutations = {
  setLoading(state, payload) {
      state.all.loading = payload ;
  },
  getAllSuccess(state, experts) {
      state.all = { items: experts };
  },
  getSkillsSuccess(state, skills) {
    state.skills = skills;
  },
  getAllFailure(state, error) {
      state.all = { error };
  }
};

export const searchExpert = {
  namespaced: true,
  state,
  actions,
  mutations
};
