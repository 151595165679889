export default function calculateDynamicColor(item){
    let currentLevel = item.level;
    let maxLevel = item.rating.skale;
    let colorType = item.rating.color ? item.rating.color : 'grey';
    let resultColor = "";
    if( currentLevel / maxLevel <= 0.2) {
        resultColor = colorType + " lighten-3";
    } else if ( currentLevel / maxLevel <= 0.4) {
        resultColor = colorType + " lighten-2";
    } else if ( currentLevel / maxLevel <= 0.6) {
        resultColor = colorType + " lighten-1";
    } else if ( currentLevel / maxLevel <= 0.8) {
        resultColor = colorType + " darken-1";
    } else if ( currentLevel / maxLevel <= 1) {
        resultColor = colorType + " darken-2";
    }
    return resultColor;
}

export const COLORS = [
    { text: 'Rot', value: 'red'},
    { text: 'Lila', value: 'purple'},
    { text: 'Blau', value: 'blue'},
    { text: 'Grün', value: 'green'},
    { text: 'Gelb', value: 'yellow'},
    { text: 'Orange', value: 'orange'},
    { text: 'Braun', value: 'brown'}
];
