<template>
  <v-container >
  <v-card class="view">
    <v-card-title>
      <h3>{{$t('i18n_master_roles')}}</h3>
    </v-card-title>
    <v-container>
        <v-tabs
          v-model="currentItem"
          fixed-tabs
          background-color="indigo"
          dark>
          <v-tab>
            {{$t('i18n_user')}}
          </v-tab>
          <v-tab>
            {{$t('i18n_master_roles')}}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
          <v-tab-item>
            <TabRoleUser/>
          </v-tab-item>

          <v-tab-item>
            <TabRoles />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
  </v-card>
        
      
  </v-container>
</template>

<script>
  import TabRoleUser from '../components/tabs/TabRoleUser.vue';
  import TabRoles from "../components/tabs/TabRoles";
  import { mapActions } from "vuex";

  export default {
    data: () => ({
      currentItem:null
    }),
    components: {
      TabRoleUser,
      TabRoles
    },
    created() {
      this.getAllPrivileges();
      this.getAllRoles();
      this.getAllUserRoles();
      this.getAll();
    },
    methods:{
      ...mapActions('rolesAndPrivileges', ['getAllRoles', 'getAllPrivileges', 'getAllUserRoles']),
      ...mapActions('users', ['getAll']),
    }
  }
</script>

<style scoped>
.view {
    width:90%;
    height:90%;
  }
</style>
