<template>
    <v-simple-table>
        <template v-slot:default>
            <tbody>
            <tr>
                <td><b>{{$t(`profil.${lang}.name`)}}:</b></td>
                <td>{{basicData.firstname}} {{basicData.lastname}}
                </td>
            </tr>
                <tr>
                <td><b>{{$t(`profil.${lang}.degree`)}}:</b></td>
                <td>{{basicData.degree}}</td>
            </tr>
                <tr>
                <td><b>{{$t(`profil.${lang}.position`)}}:</b></td>
                <td>{{basicData.position}}</td>
            </tr>
                <tr>
                <td><b>{{$t(`profil.${lang}.birthday`)}}:</b></td>
                <td>{{basicData.birthday | formatDate}}</td>
            </tr>
                <tr>
                <td><b>{{$t(`profil.${lang}.telefon`)}}:</b></td>
                <td>{{basicData.phone}}</td>
            </tr>
                <tr>
                <td><b>{{$t(`profil.${lang}.email`)}}:</b></td>
                <td>{{basicData.email}}</td>
            </tr>
                <tr>
                <td><b>{{$t(`profil.${lang}.adress`)}}:</b></td>
                <td>{{basicData.address}}</td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
    import { mapState} from "vuex";

  export default {
    props: {
      expert:Object
    },
    computed:{
        ...mapState({
            basicData:state => state.users.myUser,
            lang: state => state.general.profilLang,
        })
    },
    filters: {
      getTitle(item) {
        return item[0].kategorie;
      },
      formatDate(date) {
        if(date) {
          return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
        }
      }
    },
  }
</script>
<style scoped>

</style>
