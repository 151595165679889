import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from './store'
import vuetify from './plugins/vuetify';

import './assets/css/style.css'
import i18n from './i18n'
import VueHtmlToPaper from 'vue-html-to-paper';

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
})
Vue.use(VueHtmlToPaper);