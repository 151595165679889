<template>
    <v-container>
        <v-card class="view" v-if="!previewShare">
            <v-card-title>
                Share Expert: {{shareExpert.userDto.firstname}} {{shareExpert.userDto.lastname}}
                <v-spacer></v-spacer>
                <v-btn icon @click="deshare()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
              >
                <!-- Auswahl an Skills pro Kategorie -->
                    <!-- Auswahl an Sprache (Dummy that) später-->
                    <!-- Due Date setzen -->
                    <!-- Preview view -->
                <v-row>
                <v-col>
                    <v-select
                    v-model="publication.template"
                    :items="templates"
                    :rules="isRequired"
                    :label="$t(`profil.${lang}.template`)"
                    item-text="name"
                    item-value="id"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-autocomplete v-if="skills"
                      :label="$t(`profil.${lang}.expert_opt_skill`)"
                      v-model="publication.skills"
                      :items="skills"
                      item-text="name"
                      return-object
                      chips 
                      deletable-chips
                      multiple
                      clearable
                    >
                      <template v-slot:selection="data">
                        <v-menu offset-y left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              :input-value="data.selected"
                              close
                              @click:close="removePublicationSkill(data.item)"
                            >
                              {{ skillName(data.item.texts) }}
                            </v-chip>
                          </template>
                        </v-menu>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="{
                                data:data.item,
                                selected:publication.skills, 
                                } | selected" 
                              @change="selectSkill(data.item)">
                            </v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ skillName(data.item.texts) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                </v-col>
                </v-row>
                <v-row>
                <v-col>
                    <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="publication.dueDate"
                            :label="$t(`profil.${lang}.due_date`)"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="publication.dueDate"
                    >
                    </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-text-field
                    v-model="publication.password"
                    :label="$t(`profil.${lang}.password`)"
                    type="password"
                    counter
                    >

                    </v-text-field>
                </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" v-on:click="deshare()">{{$t(`profil.${this.lang}.cancel`)}}</v-btn>
                <v-btn :disabled="!valid" v-on:click="previewShare=true">{{$t(`profil.${this.lang}.preview`)}}</v-btn>
                <v-btn 
                    :disabled="!valid" 
                    v-on:click="save()" 
                    color="primary">
                    {{$t(`profil.${this.lang}.publish`)}}
                </v-btn>
            </v-card-actions>
        </v-card>
        <TemplateView v-else :template="selectedTemplate" :expert="retrievedExpert" :skills="simpleClassifiedSkills" @depreview="previewShare=false" :classifiedSkills="classifiedSkills"></TemplateView>
    </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import i18n from '../../i18n';
import TemplateView from './TemplateView.vue'

export default {
    name: "Publish",
    data: () => ({
        previewShare: false,
        publication: {
          skills:[],
          dueDate: "",
          template: "",
          password:"",
        },
        
        isRequired: [
            v => !!v || 'Template erforderlich'
        ],
        valid: true
    }),
    computed: {
        ...mapState({
            templates: state => state.template.all.items,
            skills: state => state.searchExpert.skills,
            simpleClassifiedSkills: state => state.users.simpleClassification,
            classifiedSkills: state => state.users.classifiedSkills,
            retrievedExpert: state => state.users.myProfile,
            lang: state => state.general.profilLang,
        }),
        selectedTemplate(){
        var ret = {};
        this.templates.forEach(element => {
          if(element.id === this.publication.template){
            ret = element;
          }
        });
        return ret;
      }
    },
    filters: {
      selected(data) {
        if(data.data.level > 0 && data.selected.indexOf(data.data) < 0) {
          data.data.userId = data.userId;
          data.selected.push(data.data);
        }
        return data.selected.indexOf(data.data) > -1; 
      }
    },
    methods: {
      ...mapActions('searchExpert', ['getAll','getSkills']),
      ...mapActions('template', ['getTemplates']),
      ...mapActions('users', ['getUserById']),
      ...mapActions('publication', ['publish']),
      deshare(){
          this.$emit('deshare');
      },
      save(){
          this.publication.user = this.shareExpert.userDto.userID;
          this.publish(this.publication);
          this.$emit('deshare');
      },
      selectSkill(item) {
        let index = this.publication.skills.indexOf(item);
        if(index < 0) {
          this.publication.skills.push(item);
        } else {
          this.publication.skills.splice(index, 1)
        }
      },
      skillName(texts) {
        for(var index in texts) {
          if(texts[index].code === this.$i18n.locale) {
            return texts[index].name
          }
        }
        return "--"
      },
      removePublicationSkill(item) {
        delete item.level;
        this.publication.skills = this.publication.skills.filter(element => element.id !== item.id)
      },
    },
    props: {
        shareExpert : Object
    },
    created(){
        this.getTemplates();
        this.getSkills(i18n.locale);
        //this.getUserById({userId: this.shareExpert.userDto.userID, language: this.$i18n.locale });
    },
    components: {
        TemplateView
    }
}
</script>
<style scoped>

</style>
