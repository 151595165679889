<template>
  <v-dialog
    v-model="dialog"
    persistent 
    width="800"
    :retain-focus="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn 
        v-show="isEditable"
        v-on="on"
        icon
        @click="openDialog()">
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        primary-title
      >
        {{$t(`profil.${lang}.basicdata`)}}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-container>
        <v-expansion-panels>
            <v-expansion-panel v-if="translations.de">
              <v-expansion-panel-header>DE</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form
                  ref="firstForm"
                  v-model="firstValid">
                  <v-row>
                      <v-col cols="12">
                        <v-text-field 
                          :label="$t(`profil.de.position`)"
                          v-model="translations.de.position"
                        ></v-text-field>
                      </v-col>
                      <v-container>
                        <v-textarea
                          :label="$t(`i18n_section_job_description`)"
                          v-model="translations.de.description"
                        >
                        </v-textarea>
                      </v-container>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel  v-if="translations.en">
              <v-expansion-panel-header>EN</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form
                  ref="firstForm"
                  v-model="firstValid">
                  <v-row>
                      <v-col cols="12">
                        <v-text-field 
                          :label="$t(`profil.en.position`)"
                          v-model="translations.en.position"
                        ></v-text-field>
                      </v-col>
                      <v-container>
                        <v-textarea
                          :label="$t(`i18n_section_job_description`)"
                          v-model="translations.en.description"
                        >
                        </v-textarea>
                      </v-container>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="closeDialog()"
        >
          {{$t('i18n_cancel')}}
        </v-btn>
        <v-btn
          :disabled="!firstValid"
          color="primary"
          @click="saveData()"
        >
          {{$t('i18n_save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  export default {
    props: {
      dialog: Boolean,
      experienceId:Number
    },
    data () {
      return {
        firstValid: true,
        requiredRules: [
          v => !!v || "Field is required"
        ],
      }
    },
    computed: {
      ...mapState({
        translations: state => state.configuration.texts,
        isEditable: state => state.general.selectedPageEditable,
        lang: state => state.general.profilLang,
      }),
    },
    methods: {
      ...mapActions('configuration', ['saveTranslation', 'getTexts']),
      openDialog() {
        this.getTexts({tableName: "experience", id: this.experienceId});
        this.$emit("closeTranslate", !this.dialog);
      },
      closeDialog() {
        this.myDialog = this.dialog;
        this.$emit("closeTranslate", !this.dialog);
      },
      saveData() {
        this.saveTranslation(this.translations);
        this.$emit("saveCloseTranslate", !this.dialog);
      }
    }
  }
</script>