<template>
  <v-list dense>
    <template v-for="(item, index) in experience">
      <v-list-item class="showhim"
        :key="item.id">
        <v-list-item-icon>
            <v-icon>mdi-briefcase</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <h3 style="margin-bottom: 5px;">{{item.organization}}</h3>
          <v-list-item-subtitle>{{item.position}}</v-list-item-subtitle>
          <v-list-item-subtitle style="margin-bottom: 15px;">{{item.start | formatDate}} {{ item.end ? '-' : ''}} {{item.end | formatDate}}</v-list-item-subtitle>
          <div>
            <VueMarkdown :source="item.description ? item.description : ''"></VueMarkdown>
          </div>
        </v-list-item-content>
        <v-list-item-action>
          <v-row class="showme" v-show="isEditable">
            <v-btn icon
              @click="deleteData(item.id)">
              <v-icon color="red lighten-1">mdi-delete</v-icon>
            </v-btn>
            <v-btn icon
              @click="editData(item)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <TranslateExperience :dialog="dialogTranslate" :experienceId="item.id" @closeTranslate="closeTranslate" @saveCloseTranslate="saveCloseTranslate"/>
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-divider
        v-if="index + 1 < experience.length"
        :key="`${index}-divider`">
      </v-divider>
    </template>
    <v-dialog
      v-model="dialog"
      max-width="310"
      persistent >
      <v-card>
        <v-card-title >{{$t(`profil.${this.lang}.msg_delete_warning`)}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            
            @click="dialog = false"
          >
            {{$t(`profil.${this.lang}.no`)}}
          </v-btn>

          <v-btn
            color="primary"
            
            @click="sendDelete()"
          >
            {{$t(`profil.${this.lang}.yes`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
import TranslateExperience from '../customDialogs/translate/TranslateExperience.vue';
import { mapState, mapActions } from "vuex";
import VueMarkdown from 'vue-markdown'

export default {
  data: () => ({
    dialog:false,
    selectedData:0,
    dialogTranslate: false
  }),
  components: {
    VueMarkdown,
    TranslateExperience
  },
  computed: {
    ...mapState({
      me: state => state.account.user,
      experience: state => state.users.experience.sort(function(a,b){
          return (new Date(a.end) - new Date(b.end)) * -1;
        }),
      isEditable: state => state.general.selectedPageEditable,
      lang: state => state.general.profilLang,
    }),
  },
  filters: {
    formatDate(date) {
      if(date) {
        return new Date(date).getMonth()+1 + "/" + new Date(date).getUTCFullYear();
      }
    },
  },
  methods: {
    ...mapActions('users', ['deleteExperience', 'getUserById']),
    deleteData(id) {
      this.selectedData = id;
      this.dialog = !this.dialog;
    },
    sendDelete() {
      this.deleteExperience({id : this.selectedData});
      this.dialog = !this.dialog;
    },
    editData(item) {
      this.$emit("editExperience", item);
    },
    closeTranslate(bOpen) {
      this.dialogTranslate = bOpen;
    },
    saveCloseTranslate(bOpen) {
      this.getUserById({userId: this.me.userID, language: this.experience[0].code});
      this.dialogTranslate = bOpen;
    }
  }
}
</script>
