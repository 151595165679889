<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent 
      width="800"
    >
      <v-card>
        <v-card-title
          primary-title
        >
          {{$t(`profil.${this.lang}.projects`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-form v-model="firstValid">
              <v-row>
                <v-col cols="6">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.customer`) + '*'"
                    v-model="selectedData.customer"
                    :rules="requiredRules"
                    required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.role`)"
                    v-model="selectedData.role">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.branch`)"
                    v-model="selectedData.branch">
                  </v-text-field>
                </v-col>
                <v-row class="date-row">
                  <v-menu
                    v-model="menuStart"
                    :close-on-content-click="false"
                    transition="scale-transition">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="startDate"
                            :label="$t(`profil.${lang}.start`) + '*'"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-on="on"
                            :rules="requiredRules"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" @input="menuStart = false" type="month" :max="today"></v-date-picker>
                  </v-menu>
                  <v-spacer/>
                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    transition="scale-transition">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="endDate"
                            :label="$t(`profil.${lang}.end`)"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="endDate"
                            @input="menuEnd = false"
                            type="month"
                            :min="startDate"
                            :disabled="!startDate"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    width="100%"
                    outlined
                    name="input-7-4"
                    :label="$t(`profil.${this.lang}.job_description`)"
                    v-model="selectedData.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>  
          </v-container>
          <small>{{'*' + $t(`profil.${this.lang}.required`)}}</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            
            @click="closeDialog()"
          >
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"
            
            @click="saveData()"
          >
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import {today} from "../../utils/date.utils";

  export default {
    props: {
      dialog: Boolean,
      selectedData:Object
    },
    data () {
      return {
        menuStart:false,
        menuEnd:false,
        myDialog:false,
        firstValid: true,
        starting:"",
        ending:"",
        requiredRules: [
          v => !!v || "Field is required"
        ],
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        lang: state => state.general.profilLang,
      }),
      today,
      startDate: {
        // getter
        get: function () {
          let start = this.starting;
          if (this.selectedData.start) {
            start = new Date(this.selectedData.start).toISOString().substr(0, 10);
          }
          return start;
        },
        // setter
        set: function (newValue) {
          this.selectedData.start = new Date(newValue);
          this.starting = newValue;
        }
      },
      endDate: {
        // getter
        get: function () {
          let end = this.ending;
          if (this.selectedData.end) {
            end = new Date(this.selectedData.end).toISOString().substr(0, 10);
          }
          return end;
        },
        // setter
        set: function (newValue) {
          this.selectedData.end = new Date(newValue);
          this.ending = newValue;
        }
      },
    },
    methods: {
      ...mapActions('users', ['saveProjects']),
      closeDialog() {
        this.myDialog = this.dialog;
        this.$emit("update-expandProjects", !this.dialog);
      },
      saveData() {
        this.selectedData.user = this.basicData;
        this.selectedData.user.id = this.basicData.userID;
        this.saveProjects(this.selectedData);
        this.myDialog = this.dialog;
        this.$emit("update-expandProjects", !this.dialog);
      }
    }
  }
</script>

<style>
  .date-row {
    margin-left: 12px;
    margin-right: 12px;
  }
</style>
