<template>
  <v-container >
    <div class="text-center" v-if="loading">
      <v-progress-circular
              size="100"
              indeterminate>
      </v-progress-circular>
    </div>
    <v-card class="view">
      <v-card-title>
        <h3>{{$t('i18n_master_config')}}</h3>
      </v-card-title>
      <v-card-text>
         <v-container>
        <v-tabs
          v-model="currentItem"
          fixed-tabs
          background-color="indigo"
          dark>
          <v-tab>
            {{$t('i18n_conf_skills')}}
          </v-tab>
          <v-tab>
            {{$t('i18n_languages')}}
          </v-tab>
          <v-tab>
            {{$t('i18n_conf_categories')}}
          </v-tab>
          <v-tab>
            {{$t('i18n_conf_ratings')}}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
          <v-tab-item>
            <TabConfigSkills/>
          </v-tab-item>

          <v-tab-item>
            <TabConfigLanguages/>
          </v-tab-item>

          <v-tab-item>
            <TabConfigClassifications/>
          </v-tab-item>

          <v-tab-item>
            <TabConfigRatings/>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      </v-card-text>
    </v-card>
     
      
  </v-container>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  import TabConfigSkills from '../components/tabs/TabConfigSkills.vue'
  import TabConfigLanguages from '../components/tabs/TabConfigLanguages.vue'
  import TabConfigClassifications from '../components/tabs/TabConfigClassifications.vue'
  import TabConfigRatings from '../components/tabs/TabConfigRatings.vue'

  export default {
    data: () => ({
      currentItem:null
    }),
    computed: {
      ...mapState({
        renderTable: state => state.configuration.renderTable,
        loading: state => state.configuration.loading
      }),
    },
    created() {
      this.getConfiguration(this.$i18n.locale);
    },
    methods: {
      ...mapActions('configuration', ['getConfiguration']),
    },
    components: {
      TabConfigSkills,
      TabConfigLanguages,
      TabConfigClassifications,
      TabConfigRatings
    }
  }
</script>
<style scoped>
.view {
    width:90%;
    height:90%;
  }
</style>