<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent 
      width="800"
    >
      <template v-slot:activator="{ on }">
        <v-btn 
          v-show="isEditable"
          v-on="on"
          icon
          @click="closeDialog()">
          <v-icon color="indigo lighten-1">mdi-pen</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          primary-title
        >
          {{$t(`profil.${lang}.basicdata`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text v-if="selectedData">
          <v-container>
            <v-form
              ref="firstForm"
              v-model="firstValid">
              <v-layout
                    align="center"
                    justify="center"
                    justify-center
                    fill-height
                >
                <div>
                  <img 
                    v-if="selectedData.image"
                    :src="selectedData.image" 
                    class="uploading-image" />
                  <v-row 
                    v-else
                    align="center"
                    justify="center">
                    <v-icon size="200px">mdi-account-off</v-icon>
                  </v-row>
                  <v-file-input :label="$t(`profil.${lang}.filename`)" :value="getImageTitle" @change="uploadAvatar"></v-file-input>
                </div>
              </v-layout>
              <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field 
                      :label="$t(`profil.${lang}.firstname`) + '*'" 
                      v-model="selectedData.firstname"
                      :rules="requiredRules"
                      required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :label="$t(`profil.${lang}.lastname`) + '*'"
                      v-model="selectedData.lastname"
                      :rules="requiredRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field 
                      :label="$t(`profil.${lang}.position`)"
                      v-model="selectedData.position"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field 
                      :label="$t(`profil.${lang}.degree`)"
                      v-model="selectedData.degree"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field 
                      :label="$t(`profil.${lang}.email`) + '*'" 
                      v-model="selectedData.email"
                      :rules="requiredRules"
                      type="email"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                      <template v-slot:activator="{ on }">
                          <v-text-field
                              v-model="birthday"
                              :label="$t(`profil.${lang}.birthday`)"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker 
                        v-model="birthday"
                        @change="selectDate()"
                        :max="today"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="selectedData.phone"
                      prepend-inner-icon="mdi-phone"
                      :label="$t(`profil.${lang}.telefon`)">
                  </v-text-field>
                  </v-col>
                  <v-container>
                    <v-text-field
                      v-model="selectedData.address"
                      prepend-inner-icon="mdi-map-marker"
                      :label="$t(`profil.${lang}.adress`)">
                    </v-text-field>
                    <v-textarea
                      v-model="selectedData.summary"
                      :label="$t(`profil.${lang}.summary`)">
                    </v-textarea>
                  </v-container>
              </v-row>
            </v-form>
          </v-container>
          <small>{{'*' + $t(`profil.${this.lang}.required`)}}</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="closeDialog()"
          >
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"
            @click="saveData()"
          >
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import {today} from "../../utils/date.utils";

  export default {
    props: {
      dialog: Boolean,
      selectedData:Object
    },
    data () {
      return {
        menu:false,
        myDialog:false,
        firstValid: true,
        requiredRules: [
          v => !!v || "Field is required"
        ],
      }
    },
    filters: {
      formatDate(date) {
        if(date) {
          return new Date(date).toISOString().substr(0, 10);
        }
      },
    },
    computed: {
      ...mapState({
        basicData: state => Object.assign({},state.users.myUser),
        isEditable: state => state.general.selectedPageEditable,
        lang: state => state.general.profilLang,
      }),
      today,
      birthday: {
        // getter
        get: function () {
          if (this.selectedData.birthday) {
            return new Date(this.selectedData.birthday).toISOString().substr(0, 10);
          }
          return "";
        },
        // setter
        set: function (newValue) {
          this.selectedData.birthday = new Date(newValue);
        }
      },
      getImageTitle() {
        let response = this.selectedData.imageTitle;
        return { name : null != response ? response:""};
      }
    },
    methods: {
      ...mapActions('users', ['saveBasicData']),
      uploadAvatar(image){
        if(null != image) {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          let that =this;
          reader.onload = e =>{
            that.selectedData.imageTitle = image.name;
            that.selectedData.image = e.target.result;
          };
        } else {
          this.selectedData.image = "";
          this.selectedData.imageTitle = "";
        }
      },
      closeDialog() {
        this.myDialog = this.dialog;
        this.$emit("update-expandBasicData", !this.dialog);
      },
      selectDate() {
        this.menu = !this.menu;
      },
      saveData() {
        this.saveBasicData({newBasicData:this.selectedData});
        this.myDialog = this.dialog;
        this.$emit("update-expandBasicData", !this.dialog);
      }
    }
  }
</script>

<style>
  .uploading-image{
    display:flex;
    max-height: 300px;
    max-width: 300px;
  }
 </style>
