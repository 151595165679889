<template>
  <v-list dense>
    <template v-for="(item, index) in myLanguages">
      <v-list-item class="showhim"
        :key="item.skill">
        <v-list-item-icon>
            <v-icon>mdi-translate</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{item.name}}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text v-if="item.rating.ratingMode"> 
            <h2>{{item.level}}</h2>
          </v-list-item-action-text>
          <v-list-item-action-text v-if="!item.rating.ratingMode"> 
            <h2>{{item.rating.allowedValues[item.level]}}</h2>
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
      <v-divider
        v-if="index + 1 < myLanguages.length"
        :key="index"
      ></v-divider>
    </template>
    <v-dialog
      v-model="dialog"
      max-width="310"
      persistent >
      <v-card>
        <v-card-title >{{$t(`profil.${this.lang}.msg_delete_warning`)}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            
            @click="dialog = false"
          >
            {{$t(`profil.${this.lang}.no`)}}
          </v-btn>

          <v-btn
            color="primary"
            
            @click="sendDelete()"
          >
            {{$t(`profil.${this.lang}.yes`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  export default {
    data: () => ({
      dialog:false,
      selectedData:null
    }),
    computed: {
      ...mapState({
        myLanguages: state => state.users.myLanguages,
        basicData: state => state.users.myUser,
        lang: state => state.general.profilLang,
      }),
     // sortedLanguages() {
     //     let that = this;
     //     if(that.myLanguages != null) {
     //       that.myLanguages.sort((a, b) => a.name > b.name ? 1 : -1);
     //     }
     //     return that.myLanguages;
     //   }
    },
    methods: {
      ...mapActions('users', ['deleteMyLanguages']),
      deleteData(id) {
        this.selectedData = id;
        this.dialog = !this.dialog;
      },
      sendDelete() {
        this.deleteMyLanguages({
            userId: this.basicData.userID,
            id: this.selectedData
          });
        this.dialog = !this.dialog;
      },
      editData(item) {
        this.$emit("editLanguage", item);
      }
    }
  }
</script>
