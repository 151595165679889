<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="searchInput">
            <v-text-field 
              :label="$t(`profil.${this.lang}.firstname`)" 
              v-model.trim="advancedSearch.firstname"
            ></v-text-field>
          </v-col>
          <v-col class="searchInput">
            <v-text-field 
              :label="$t(`profil.${this.lang}.lastname`)" 
              v-model.trim="advancedSearch.lastname"
            ></v-text-field>
          </v-col>
          <v-col class="searchInput">
            <v-text-field 
              :label="$t(`profil.${this.lang}.position`)"
              v-model.trim="advancedSearch.position"
            ></v-text-field>
          </v-col>
          <v-col class="searchInput">
            <v-text-field 
              :label="$t(`profil.${this.lang}.expert_branch`)" 
              v-model.trim="advancedSearch.branch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="searchInput">
            <v-autocomplete v-if="skills.length"
              :label="$t(`profil.${this.lang}.expert_must_skill`)"
              v-model="selectedSkills"
              :items="skills"
              item-text="name"
              return-object
              chips 
              deletable-chips
              multiple
              clearable
              auto-select-first
            >
              <template v-slot:selection="data">
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      :input-value="data.selected"
                      close
                      @click:close="removeSkill(data.item)"
                    >
                      <v-avatar left :class="calculateDynamicColor(data.item)">
                        <span>{{data.item.level}}</span>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-rating
                      background-color="blue"
                      color="primary"
                      dense
                      full-icon="mdi-checkbox-blank"
                      empty-icon="mdi-checkbox-blank-outline"
                      v-model="data.item.level"
                      :length="data.item.rating.skale"
                      size="25">
                    </v-rating>
                  </v-list>
                </v-menu>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="searchInput">
            <v-autocomplete v-if="languages.length"
              :label="$t(`profil.${this.lang}.expert_must_language`)"
              v-model="selectedLanguages"
              :items="languages"
              item-text="name"
              return-object
              chips 
              deletable-chips
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      :input-value="data.selected"
                      close
                      @click:close="removeLanguage(data.item)"
                    >
                      <v-avatar left :class="calculateDynamicColor(data.item)">
                        <span>{{data.item.level}}</span>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-rating
                      background-color="blue"
                      color="primary"
                      dense
                      full-icon="mdi-checkbox-blank"
                      empty-icon="mdi-checkbox-blank-outline"
                      v-model="data.item.level"
                      :length="data.item.rating.skale"
                      size="25">
                    </v-rating>
                  </v-list>
                </v-menu>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete v-if="optionalSkills"
              :label="$t(`profil.${this.lang}.expert_opt_skill`)"
              v-model="selectedOptionalSkills"
              :items="optionalSkills"
              item-text="name"
              return-object
              chips 
              deletable-chips
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      :input-value="data.selected"
                      close
                      @click:close="removeOptionalSkill(data.item)"
                    >
                      <v-avatar left :class="calculateDynamicColor(data.item)">
                        <span>{{data.item.level}}</span>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-rating
                      background-color="blue"
                      color="primary"
                      dense
                      full-icon="mdi-checkbox-blank"
                      empty-icon="mdi-checkbox-blank-outline"
                      v-model="data.item.level"
                      :length="data.item.rating.skale"
                      size="25">
                    </v-rating>
                  </v-list>
                </v-menu>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="searchInput">
            <v-autocomplete v-if="optionalLanguages"
              :label="$t(`profil.${this.lang}.expert_opt_language`)"
              v-model="selectedOptionalLanguages"
              :items="optionalLanguages"
              item-text="name"
              return-object
              chips 
              deletable-chips
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      :input-value="data.selected"
                      close
                      @click:close="removeOptionalLanguage(data.item)"
                    >
                      <v-avatar left :class="calculateDynamicColor(data.item)">
                        <span>{{data.item.level}}</span>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-rating
                      background-color="blue"
                      color="primary"
                      dense
                      full-icon="mdi-checkbox-blank"
                      empty-icon="mdi-checkbox-blank-outline"
                      v-model="data.item.level"
                      :length="data.item.rating.skale"
                      size="25">
                    </v-rating>
                  </v-list>
                </v-menu>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            color="teal"
            text
            v-bind="attrs"
            v-on="on"
            @click="getAll($i18n.locale)">
            <v-icon>mdi-filter-remove</v-icon>
          </v-btn>
        </template>
        <span>{{$t(`profil.${this.lang}.reset_filter`)}}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn 
        color="error" 
        @click="reset">{{$t(`profil.${this.lang}.reset`)}}</v-btn>
      <v-btn 
        :disabled="!formValid" 
        color="primary" 
        @click="searchOn">{{$t(`profil.${this.lang}.search`)}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import _ from "lodash";
  import calculateDynamicColor from "../../utils/color.utils";
  import i18n from '../../i18n';

  export default {
    data: () => ({
      advancedSearch:{
        firstname: "",
        lastname: "",
        position: "",
        branch: "",
        code: i18n.locale,
        skills: [],
        languages: [],
        optionalSkills: [],
        optionalLanguages: []
      },
      optionalSkills: null,
      optionalLanguages: null
    }),
    updated() {
      if(null == this.optionalSkills && null == this.optionalLanguages ) {
        this.optionalSkills = _.cloneDeep(this.skills);
        this.optionalLanguages = _.cloneDeep(this.languages);
      }
    },
    computed: {
      ...mapState({
        skills: state => state.searchExpert.skills,
        languages: state => state.users.languages,
        lang: state => state.general.profilLang,
      }),
      formValid() {
        return 0 < this.advancedSearch.firstname.length ||
               0 < this.advancedSearch.lastname.length ||
               0 < this.advancedSearch.position.length ||
               0 < this.advancedSearch.skills.length ||
               0 < this.advancedSearch.branch.length ||
               0 < this.advancedSearch.languages.length;
      },
      selectedSkills: {
        get: function() {
          return this.advancedSearch.skills;
        },
        set: function(value) {
          for(var index in value) {
            value[index].level = null != value[index].level && 0 < value[index].level ? value[index].level : 1
          }
          this.advancedSearch.skills = value;
        },
      },
      selectedLanguages: {
        get: function() {
          return this.advancedSearch.languages;
        },
        set: function(value) {
          for(var index in value) {
            value[index].level = null != value[index].level && 0 < value[index].level ? value[index].level : 1
          }
          this.advancedSearch.languages = value;
        },
      },
      selectedOptionalSkills: {
        get: function() {
          return this.advancedSearch.optionalSkills;
        },
        set: function(value) {
          for(var index in value) {
            value[index].level = null != value[index].level && 0 < value[index].level ? value[index].level : 1
          }
          this.advancedSearch.optionalSkills = value;
        },
      },
      selectedOptionalLanguages: {
        get: function() {
          return this.advancedSearch.optionalLanguages;
        },
        set: function(value) {
          for(var index in value) {
            value[index].level = null != value[index].level && 0 < value[index].level ? value[index].level : 1
          }
          this.advancedSearch.optionalLanguages = value;
        },
      }
    },
    filters: {
      selected(data) {
        if(data.data.level > 0 && data.selected.indexOf(data.data) < 0) {
          data.data.userId = data.userId;
          data.selected.push(data.data);
        }
        return data.selected.indexOf(data.data) > -1; 
      }
    },
    methods: {
      ...mapActions('searchExpert', ['getAll', 'searchExperts']),
      calculateDynamicColor,
      setMinimuLevel(data) {
        return data;
      },
      reset() {
        for(var i in this.advancedSearch.languages) {
            delete this.advancedSearch.languages[i].level;
        }
        for(var j in this.advancedSearch.skills) {
            delete this.advancedSearch.skills[j].level;
        }
        this.advancedSearch = {
          firstname: "",
          lastname: "",
          position: "",
          branch:"",
          code: i18n.locale,
          skills: [],
          languages: [],
          optionalSkills:[],
          optionalLanguages:[]
        };
        this.getAll(i18n.locale);
      },
      skillName(texts) {
        for(var index in texts) {
          if(texts[index].code === this.$i18n.locale) {
            return texts[index].name.toString()
          }
        }
        return "--"
      },
      selectSkill(item) {
        let index = this.selectedSkills.indexOf(item);
        if(index < 0) {
          item.level = 1;
          this.selectedSkills.push(item);
        } else {
          item.level = 0;
          this.selectedSkills.splice(index, 1)
        }
      },
      searchOn() {
        this.searchExperts(this.advancedSearch);
      },
      removeSkill(item) {
        delete item.level;
        this.advancedSearch.skills = this.advancedSearch.skills.filter(element => element.id !== item.id)
      },
      removeLanguage(item) {
        delete item.level;
        this.advancedSearch.languages = this.advancedSearch.languages.filter(element => element.id !== item.id)
      },
      removeOptionalSkill(item) {
        delete item.level;
        this.advancedSearch.optionalSkills = this.advancedSearch.optionalSkills.filter(element => element.id !== item.id)
      },
      removeOptionalLanguage(item) {
        delete item.level;
        this.advancedSearch.optionalLanguages = this.advancedSearch.optionalLanguages.filter(element => element.id !== item.id)
      }
    }
  }
</script>
<style scoped>
.searchInput {
  min-width : 200px;
}
</style>
