<template>
    <v-dialog
        v-model="dialog"
        max-width="310"
        persistent >
        <v-card>
            <v-card-title>{{$t(`profil.${this.lang}.msg_delete_warning`)}}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                color="error"
                @click="no()"
                >
                {{$t(`profil.${this.lang}.no`)}}
                </v-btn>

                <v-btn
                color="primary"
                @click="yes()"
                >
                {{$t(`profil.${this.lang}.yes`)}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
 import { mapState } from "vuex";

  export default {
    props: {
      dialog: Boolean
    },
    computed: {
      ...mapState({
        lang: state => state.general.profilLang,
      }),
    },
    methods: {
      no() {
        this.$emit("fire-no", false);
      },
      yes() {
        this.$emit("fire-yes", false);
      }
    }
  }
</script>