const state = {
    type: "info",
    message: null
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }, message) {
        commit('success', message);
    }
};

const ALERT_TIMEOUT=5000;

const mutations = {
    success(state, message) {
        setAlertState(state, message, 'success');
        setTimeout(() => setAlertState(state, null, "info")
            , ALERT_TIMEOUT);
    },
    error(state, message) {
        setAlertState(state, message, 'error');
        setTimeout(() => setAlertState(state, null, "info")
            , ALERT_TIMEOUT);
    },
    clear(state) {
        setAlertState(state, null, "info");
    }
};

function setAlertState(state, message, status) {
    state.type = status;
    state.message = message;
}

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
