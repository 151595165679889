<template >
  <v-data-table
    :headers="ratingHeader"
    :items="ratings"
    item-key="skaleName"
    :expanded.sync="expanded"
    :search="search"
    show-expand>
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{$t('i18n_conf_ratings')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('i18n_search')"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <DeleteWarning :dialog="deleteDialog" @fire-no="cancelDelete" @fire-yes="sendDelete"/>
        <TranslateRating :dialog="translateDialog" @fire-close="closeTranslate"/>
        <EditConfigRatings :toEdit="newRating" :dialog="dialog" @fire-save="save" @fire-close="initEdit"/>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pen
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        small
        @click="translateItem(item)"
      >
        mdi-translate
      </v-icon>
    </template>
    <template v-slot:item.ratingMode="{ item }">
      <v-icon
        v-if="item.ratingMode"
      >
        mdi-check
      </v-icon>
      <v-icon
        v-if="!item.ratingMode"
      >
        mdi-close
      </v-icon>
    </template>
    <template v-slot:item.allowedValues="{  item }">
      <div v-for="(value, index) in item.allowedValues" :key="value">
        <v-list-item-subtitle>{{index}}: {{value}}</v-list-item-subtitle>
      </div>
    </template>
    <template v-slot:expanded-item="{ item }">
      <v-col>
        <div>{{item.description}}</div>
      </v-col>
    </template>
  </v-data-table>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  import EditConfigRatings from '../customDialogs/EditConfigRatings.vue';
  import DeleteWarning from '../customDialogs/DeleteWarning.vue';
  import TranslateRating from '../customDialogs/translate/TranslateRating.vue';

  export default {
    data: () => ({
      currentItem:null,
      dialog:false,
      deleteDialog:false,
      translateDialog:false,
      search:"",
      expanded:[],
      maxSkales:[2,3,4,5,6,7,8,9,10],
      newRating: {
        id:null,
        skaleName:null,
        code: null,
        skale:2,
        description:"",
        allowedValues:{},
        ratingMode:true,
        color: "red"
      },
    }),
    computed: {
      ...mapState({
        ratings: state => state.configuration.rating,
        isEditable: state => state.general.selectedPageEditable
      }),
      ratingHeader() {
        var result = [
          { text: this.$t('i18n_conf_ratings'), align: 'left', sortable: false, value: 'skaleName' },
          { text: this.$t('i18n_conf_max_value'), value: 'skale' },
          { text: this.$t('i18n_conf_rating'), value: 'allowedValues' },
          { text: this.$t('i18n_conf_rating_mode'), value: 'ratingMode' }
        ];

        if(this.isEditable) {
          result.push({ text: this.$t('i18n_actions'), value: 'action', sortable: false });
        }
        result.push({ text: '', value: 'data-table-expand' });
        return result;
      }
    },
    methods: {
      ...mapActions('configuration', ['saveRating', 'deleteRating', 'getTexts']),
      save(toSave) {
        this.saveRating(toSave);
        this.initEdit(false);
      },
      editItem (item) {
        this.dialog = true;
        this.removeObsoleteEntries(item.allowedValues, item.skale);
        this.newRating = Object.assign({}, item);
      },
      translateItem (item) {
        this.translateDialog = true;
        this.getTexts({tableName: "rating", id: item.id});
      },
      closeTranslate() {
        this.languageId = null;
        this.translateDialog = false;
      },
      deleteItem (item) {
        this.currentItem = item;
        this.deleteDialog = true;
      },
      sendDelete() {
        this.deleteRating(this.currentItem);
        this.deleteDialog = false;
      },
      cancelDelete() {
        this.currentItem = null;
        this.deleteDialog = false;
      },
      initEdit(isOpen) {
        this.newRating = {
          id:null,
          skaleName:null,
          code: this.$i18n.locale,
          skale:2,
          description:"",
          allowedValues:{},
          ratingMode:true
        };
        this.dialog = isOpen;
      },
      removeObsoleteEntries(allowedValues, scale) {
        if(scale < Object.keys(allowedValues).length){
          for(let i = scale + 1; i <= Object.keys(allowedValues).length + 1; i++){
            delete allowedValues[i];
          }
        }
      }
    },
    components: {
        EditConfigRatings,
        DeleteWarning,
        TranslateRating
    }
  }
</script>
