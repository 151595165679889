import { authHeader, hostUrl  } from '../router';
import { handleResponse } from "../utils/service.utils";

export const configurationService = {
  getConfiguration,
  getTexts,
  saveTranslation,
  deleteTranslation,
  saveClassifications,
  deleteClassification,
  saveLanguage,
  deleteLanguage,
  saveRating,
  deleteRating,
  saveSkill,
  deleteSkill
};

function getConfiguration(code) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(hostUrl() + `/configuration?code=${code}`, requestOptions).then(handleResponse);
}

function getTexts(data) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(hostUrl()  + `/texts?tablename=${data.tableName}&&foreignkey=${data.id}`, requestOptions).then(handleResponse);
}

function saveTranslation(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/saveTexts`, requestOptions).then(handleResponse);
}

function deleteTranslation(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/deleteTexts`, requestOptions).then(handleResponse);
}

function saveClassifications(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/saveClassifications`, requestOptions).then(handleResponse);
}

function deleteClassification(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/deleteClassification`, requestOptions).then(handleResponse);
}

function saveLanguage(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/saveLanguage`, requestOptions).then(handleResponse);
}

function deleteLanguage(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/deleteLanguage`, requestOptions).then(handleResponse);
}

function saveRating(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/saveRating`, requestOptions).then(handleResponse);
}

function deleteRating(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/deleteRating`, requestOptions).then(handleResponse);
}

function saveSkill(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/saveSkill`, requestOptions).then(handleResponse);
}

function deleteSkill(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(hostUrl() + `/deleteSkill`, requestOptions).then(handleResponse);
}
