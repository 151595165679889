<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      @click:outside="closeDialog()"
      width="600">
      <v-card v-if="expert.userDto">
        <v-card-text>
          <v-row
            align="start">
              <v-col cols="12" md="4" sm="3">
                <v-avatar
                  class="profile"
                  color="grey"
                  size="164"
                  tile
                >
                  <v-img :src="expert.userDto.image"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="8" sm="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{expert.userDto.firstName}} {{expert.userDto.lastName}}</v-list-item-title>
                    <v-list-item-subtitle>{{expert.userDto.degree}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{expert.userDto.position}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{expert.userDto.birthday | formatDate}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{expert.userDto.phone}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{expert.userDto.email}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{expert.userDto.address}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{expert.userDto.summary}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          <div
            v-for="item in expert.simpleClassification"
            :key="item.classification.id">
            <h4>{{item.classification.name}}</h4>
            <ChipsWithTooltips :items="sortSkillsByLevel(item.skills)"></ChipsWithTooltips>
          </div>
          <div>
            <h4>{{$t('i18n_languages')}}</h4>
            <ChipsWithTooltips :items="sortSkillsByLevel(expert.userLanguageDto)"></ChipsWithTooltips>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import ChipsWithTooltips from "../customItems/ChipsWithTooltips";
  import { sortSkillsByLevel } from "../../utils/profilePage.utils";

  export default {
    components: { ChipsWithTooltips },
    props: {
      dialog: Boolean,
      expert:Object
    },
    data: () => ({
      flag : false
    }),
    filters: {
      formatDate(date) {
        if(date) {
          return new Date(date).toISOString().substr(0, 10);
        }
      },
    },
    methods: {
      closeDialog() {
        this.$emit("closeDetailDialog", !this.dialog);
      },
      sortSkillsByLevel
    }
  }
</script>
