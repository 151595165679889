import jsPDF from 'jspdf'
import html2canvas from "html2canvas"


export function createPDF (template, ref) {
  html2canvas(ref).then(function(canvas){
    let pdfName = template.name; 
    var wid= 500;
    var hgt= 500;
    var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
    var hratio = hgt/wid
    var doc = new jsPDF('p','pt','a4');
    var width = doc.internal.pageSize.width;    
    var height = width * hratio
    doc.addImage(img,'JPEG',20,20, width, height);
    doc.save(pdfName);
});
  
 

  //doc.html("<h1>Hello World</h1><p>test</p>",function(doc){doc.save(pdfName)}, 10, 10);
  //doc.save(pdfName);
  //doc.text("Hello World", 10, 10);
  //doc.save(pdfName + '.pdf');
}

