<template>
    <v-card class="profile" flat>
        <v-card-title>
            Bildung
        </v-card-title>
        <v-card-text>
            <v-simple-table v-if="table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Abschluss</th>
                                    <th>Fachbereich</th>
                                    <th>Institution</th>
                                    <th>Note</th>
                                    <th>Start</th>
                                    <th>Ende</th>
                                    <th v-if="description">Beschreibung</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="study in studies" :key="study.id" class="print-section">
                                    <td>{{study.degree}}</td>
                                    <td>{{study.studieField}}</td>
                                    <td>{{study.school}}</td>
                                    <td>{{study.score}}</td>
                                    <td>{{study.start | formatDate}}</td>
                                    <td>{{study.end | formatDate}}</td>
                                    <td v-if="description">
                                        <template v-if="formatted">
                                            <VueMarkdown :source="study.description"></VueMarkdown>
                                        </template>
                                        <template v-else>
                                            {{study.description}}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-container  v-else-if="timeline">
                                <v-timeline dense>
                                    <v-timeline-item
                                    v-for="study in studies" :key="study.id"
                                    small
                                    :color="template.color"
                                    class="print-section"
                                    >
                                    <v-card>
                                        <v-card-title>
                                           {{study.degree}} {{study.studieField}} 
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{study.school}} {{study.start | formatDate}} - {{study.end | formatDate}} 
                                        </v-card-subtitle>
                                        <v-card-text>
                                            {{study.description}}
                                        </v-card-text>
                                    </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                                
                    </v-container>
                    <v-list dense v-else>
                        <template v-for="(item, index) in studies">
                            <v-list-item class="showhim print-section"
                                :key="item.school">
                                <v-list-item-icon>
                                    <v-icon>mdi-school</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                <h3 style="margin-bottom: 5px;">{{item.school}}</h3>
                                <h5>{{item.degree}} - Note:{{item.score}}</h5>
                                <v-list-item-subtitle>{{item.studieField}}</v-list-item-subtitle>
                                <v-list-item-subtitle style="margin-bottom: 15px;">{{item.start | formatDate}} {{ item.end ? '-' : ''}} {{item.end | formatDate}}</v-list-item-subtitle>
                                <template v-if="description">
                                    <div v-if="formatted">
                                        <VueMarkdown :source="item.description"></VueMarkdown>
                                    </div>
                                    <div v-else>
                                        {{item.description}}
                                    </div>
                                </template>
                                
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                                v-if="index + 1 < studies.length"
                                :key="index"
                            ></v-divider>
                            </template>
                    </v-list>
        </v-card-text>

    </v-card>
</template>
 
<script>
    //import { mapState } from "vuex";
    import VueMarkdown from 'vue-markdown';
    export default{
        data: () => ({
            
        }),
        props:{
            expert: Object,
            template: Object
        },
        computed: {
            //...mapState({
            //    studies: state => state.users.studies.sort(function(a,b){
            //    return (new Date(a.end) - new Date(b.end)) * -1;
            //    })
            //}),
            studies(){
                return this.expert.studies;
            },
            table(){
                return this.template.educationLayout === 'TABLE';
            },
            timeline(){
                return this.template.educationLayout === 'TIMELINE';
            },
            compact(){
                 return this.template.educationLayout === 'COMPACT';
            },
            description(){
                return this.template.educationDescription !== 'NONE';
            },
            formatted(){
                return this.template.educationDescription === 'FORMATTED_TEXT'
            }
        },
        filters : {
            formatDate(date) {
                if(date) {
                    return new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1) + "/" + new Date(date).getUTCFullYear();
                }
            }
        },
        components : {
            VueMarkdown
        }
    }
</script>

<style scoped>
.profile {
    width: 100%;
}
.v-timeline-item{
 page-break-inside: avoid;
   visibility: visible;
  height:auto!important;

     overflow-y: hidden;
}
.profile {
    page-break-inside: avoid;
    visibility: visible;
    height: auto!important;
    overflow-y: hidden;
}
</style>
