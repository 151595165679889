<template>
<v-content>
    <v-app-bar
      app
      color="white"
    >
      <v-spacer></v-spacer>
      <v-img src="../assets/logo_aqs.png" contain apsect-ration="1.7" max-height="64" max-width="207"></v-img>
      <v-spacer></v-spacer>
      <span class="push">Version: {{ metaData.version }}</span>
    </v-app-bar>
    <v-content class="content">
      <v-card class="mx-auto login">
        <v-card-title>
            <h2>{{$t('i18n_forgot_password')}}</h2>
        </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                    v-model="sendEmail.email"
                    :rules="EmailRules"
                    :label="$t('i18n_email')"
                    outlined
                    @keypress.enter="onSubmit">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4"
                    @click="onSubmit">
                    {{$t('i18n_reset_password')}}
                </v-btn>
                <router-link to="/login" class="btn btn-link">{{$t('i18n_cancel')}}</router-link>
              </v-container>
            </v-card-actions>
      </v-card>
    </v-content>
</v-content>
    
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    data () {
        return {
            email:null,
            submitted: false,
            EmailRules: [
                v => !!v || 'Email erforderlich'
            ],
            valid: true,
            langs: ['en', 'de'],
            sendEmail: {
              serverOrigin: window.location.origin + "/resetPassword",
              email:""
            }
        }
    },
    computed: {
        ...mapState({
         metaData: state => state.meta.meta})
    },
    methods: {
        ...mapActions('account', ['retrievePassword']),
        ...mapActions('meta', ['getMetaData']),
        onSubmit () {
          this.retrievePassword(this.sendEmail)
        }
    }
};
</script>
<style scoped>
.login{
    width: 50%;
    height: 300px;
}
.container {
    align-content: center;
}
</style>
