<template>
    <v-card>
        <v-card-title>
            Enter Password
        </v-card-title>
        <v-card-text>
            <v-text-field
            type="password"
            v-model="password"
            >

            </v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" v-on:click="submit(password)">Submit</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    data:() => ({
        password: ""
    }),
    methods: {
        submit(password){
            this.$emit("publicationLogin",password)
        }
    },
}
</script>