<template >
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('i18n_edit_role_user', { name: toEdit.fullname}) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="userRoleForm"
            v-model="formValid">
            <v-autocomplete
              v-model="toEdit.roleId"
              :items="roles"
              item-text="name"
              item-value="id"
              :label="$t('i18n_role')"
              >
            </v-autocomplete>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"  @click="close">{{$t('i18n_cancel')}}</v-btn>
        <v-btn color="primary"  @click="save">{{$t('i18n_save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    currentItem:null,
    formValid:true
  }),
  props: {
    toEdit: Object,
    dialog: Boolean
  },
  computed: {
    ...mapState({
      roles: state => state.rolesAndPrivileges.roles,
      isEditable: state => state.general.selectedPageEditable
    })
  },
  methods: {
    ...mapActions('configuration', ['saveRating', 'deleteRating']),
    close() {
      this.$emit("fire-close", !this.dialog);
    },
    save() {
      this.$refs.userRoleForm.validate()
          if(this.formValid) {
              this.$emit("fire-save", this.toEdit);
          }
        }
    }
  }
</script>
