import { authHeader, hostUrl } from "../router";
import { handleResponse } from "../utils/service.utils";

export const roleService = {
    getAllRoles,
    getAllUserRoles,
    getAllPrivileges,
    saveRole,
    editRole,
    deleteRole,
    saveRoleUser,
    deleteRoleUser
};

function getAllRoles() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(hostUrl() + `/getRoles`, requestOptions).then(handleResponse);
}

function getAllUserRoles() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(hostUrl() + `/getUserRoles`, requestOptions).then(handleResponse);
}

function getAllPrivileges() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(hostUrl() + `/getPrivileges`, requestOptions).then(handleResponse);
}

function saveRole(role) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(role.newRole)
    };

    return fetch(hostUrl() + `/saveRole`, requestOptions).then(handleResponse);
}

function editRole(role) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(role)
    };

    return fetch(hostUrl() + `/createRole`, requestOptions).then(handleResponse);
}

function deleteRole(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: id
    };

    return fetch(hostUrl() + `/deleteRole`, requestOptions).then(handleResponse);
}

function saveRoleUser(role) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(role.newRoleUser)
    };

    return fetch(hostUrl() + `/saveRoleUser`, requestOptions).then(handleResponse);
}

function deleteRoleUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: id
    };

    return fetch(hostUrl() + `/deleteRoleUser`, requestOptions).then(handleResponse);
}