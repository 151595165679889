<template>
  <v-list dense>
    <template v-for="(item, index) in certificates">
      <v-list-item class="showhim"
        :key="item.skill">
        <v-list-item-icon>
            <v-icon>mdi-certificate</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <h3>{{item.name}}</h3>
          <v-list-item-subtitle>{{item.issued_by}}</v-list-item-subtitle>
          <v-list-item-subtitle class="text--primary">{{item | validDate}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row class="showme" v-show="isEditable">
              <v-btn icon
              @click="deleteData(item.id)">
              <v-icon color="red lighten-1">mdi-delete</v-icon>
            </v-btn>
            <v-btn icon
              @click="editData(item)">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-divider
        v-if="index + 1 < certificates.length"
        :key="index"
      ></v-divider>
    </template>
    <v-dialog
      v-model="dialog"
      max-width="310"
      persistent >
      <v-card>
        <v-card-title >{{$t(`profil.${this.lang}.msg_delete_warning`)}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            
            @click="dialog = false"
          >
            {{$t(`profil.${this.lang}.no`)}}
          </v-btn>

          <v-btn
            color="primary"
            
            @click="sendDelete()"
          >
            {{$t(`profil.${this.lang}.yes`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
  import {mapActions, mapState} from "vuex";

  export default {
    data: () => ({
      dialog:false,
      selectedData:0
    }),
    filters: {
      validDate (item) {
        return item.dateIssued !== null || item.dateIssued !== ""
                ? new Date(item.dateIssued).getMonth() + 1 + "/" + new Date(item.dateIssued).getUTCFullYear()
                : "";
      }
    },
    computed: {
      ...mapState({
        certificates: state => state.users.certificates.sort(function(a,b){
          return (new Date(a.dateIssued) - new Date(b.dateIssued)) * -1;
        }),
        isEditable: state => state.general.selectedPageEditable,
        lang: state => state.general.profilLang,
      }),
    },
    methods: {
      ...mapActions('users', ['deleteCertificate']),
      deleteData(id) {
        this.selectedData = id;
        this.dialog = !this.dialog;
      },
      sendDelete() {
        this.deleteCertificate({id : this.selectedData});
        this.dialog = !this.dialog;
      },
      editData(item) {
        this.$emit("editCertificate", item);
      }
    }
  }
</script>
