<template>
  <v-card class="templateCard" @click="select">
      <v-card-title>
        #{{template.id}} {{template.name}} <v-spacer></v-spacer> 
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
            </v-btn>
          </template>
          <v-list>
              <v-list-item @click="deleteTemplate(template)">
                <v-list-item-title>
                  Delete
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="previewTemplate(template)">
                <v-list-item-title>
                  Preview
                </v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
          {{template.description}}
      </v-card-text>
  </v-card>
</template>
 
<script>
  export default {
    name: 'Templates',
    props:{
        template : Object
    },
    methods: {
        select(){
            this.$emit("selected",this.template);
        },
        deleteTemplate(){
          this.$emit("deleted",this.template);
        },
        previewTemplate(){
          this.$emit("previewed",this.template);
        }
    }
  }
</script>

<style>
    .templateCard {
        height: 100%
    }
    .v-list-item__title {
        margin-bottom: 13px!important;
    }
    .v-chip{
    margin:4px;

    }
    @media print{
    v-main{
    padding:0px;
    }
  .v-timeline {
  padding-top:22px;
  }
    }
</style>
