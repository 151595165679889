import { authHeader, hostUrl } from '../router';
import { handleResponse } from "../utils/service.utils";

export const templateService = {
    getAll,save,update,getTemplates,deleteTemplate
}

function getAll(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(hostUrl() + `/template`, requestOptions).then(handleResponse);
}
function save(data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(hostUrl() + `/template`, requestOptions).then(handleResponse);
}
function update(data){
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(hostUrl() + `/template/`+data.id, requestOptions).then(handleResponse);
}
function getTemplates(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(hostUrl() + `/template/section`, requestOptions).then(handleResponse);
}
function deleteTemplate(data){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(hostUrl() + `/template/`+data.id, requestOptions).then(handleResponse);
}
