<template >
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{}">
      <v-btn color="primary" dark class="mb-2" @click="close" v-show="isEditable">{{$t('i18n_new')}}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('i18n_conf_edit_classification')}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="classificationForm"
            v-model="formValid">
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  :items="classificationsWithoutSelf"
                  :filter="customFilter"
                  return-object
                  clearable
                  v-model="selectedParent"
                  :label="$t('i18n_conf_parent_category')">
                  <template v-slot:selection="data">
                    {{ constructClassificationHierarchy(data.item) }}
                  </template>
                  <template v-slot:item="data">
                    {{ constructClassificationHierarchy(data.item) }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  :label="$t('i18n_conf_classification_name') + '*'"
                  :rules="formRules"
                  v-model="toEdit.name">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"  @click="close">{{$t('i18n_cancel')}}</v-btn>
        <v-btn color="primary" @click="save">{{$t('i18n_save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from "vuex";
  import { constructClassificationHierarchy } from "../../utils/configurationPage.utils";

  export default {
    data: () => ({
      editClassificationName: false,
      formValid: true,
      formRules: [
                v => !!v || "Field is required"
            ]
    }),
    props: {
      toEdit: Object,
      dialog: Boolean,
      selectedParent: Object
    },
    computed: {
      ...mapState({
        classifications: state => state.configuration.classifications,
        isEditable: state => state.general.selectedPageEditable
      }),
      classificationsWithoutSelf() {
        if(null != this.toEdit.id) {
          return this.classifications.filter(p => p.id !== this.toEdit.id
                  && ((null == p.parent) || p.parent.id !== this.toEdit.id));
        }
        return this.classifications;
      },
      classificationHeader() {
        return [
          {
            text: 'Kategorie',
            align: 'left',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Schicht',
            value: 'layer',
          },
          { text: 'Oberkategorie', value: 'parent.name' },
          { text: 'Aktionen', value: 'action', sortable: false },
        ]
      },
    },
    watch: {
      selectedParent(newVal) {
        if (newVal == null) {
          newVal = { layer : 0 }
        }
        this.toEdit.parent = newVal.id == null ? null : newVal;
        this.toEdit.layer = newVal.layer + 1;
      }
    },
    mounted() {
        this.toEdit.layer = 1;
    },
    methods: {
      close() {
        this.$emit("fire-close", !this.dialog);
      },
      save() {
        this.$refs.classificationForm.validate();
        if(this.formValid) {
          this.$emit("fire-save", this.toEdit);
        }
      },
      customFilter (item, queryText) {
        const textOne = item.name.toLowerCase();
        const searchText = queryText.toLowerCase();

        return textOne.indexOf(searchText) > -1;
      },
      constructClassificationHierarchy
    }
  }
</script>
