 import {templateService} from '../services';

 const state = {
    all: {},
    template: {},
    templates: [],
    sections: {}
};

const actions = {
    getAll({commit}){
        commit('setLoading', true);
        templateService.getAll().then(
            templates => {
                commit('getAllSuccess',templates);
                commit('setLoading', false);
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    },
    getTemplates({commit}){
        commit('setLoading', true);
        templateService.getAll().then(
            templates => {
                commit('getAllSuccess',templates);
                commit('setLoading', false);
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    },
    delete({commit},data){
        commit('setLoading', true);
        if(data.id){
            templateService.deleteTemplate(data).then(
                success => {
                    commit('deleteTemplate',success,data);
                    templateService.getAll().then(
                        templates => {
                            commit('getAllSuccess',templates);
                            commit('setLoading', false);
                        },
                        error => {
                            commit('requestFailure', error);
                            commit('setLoading', false);
                        }
                    );
                },
                error => {
                    commit('requestFailure', error);
                }
            );
        }
    },
    save({commit},data){
        commit('setLoading', true);
        if(data.id){
            templateService.update(data).then(
                template => {
                    commit('saveTemplate',template);
                    templateService.getAll().then(
                        templates => {
                            commit('getAllSuccess',templates);
                            commit('setLoading', false);
                        },
                        error => {
                            commit('requestFailure', error);
                            commit('setLoading', false);
                        }
                    );
                    commit('setLoading', false);
                },
                error => {
                    commit('requestFailure', error);
                    commit('setLoading', false);
                }
            );
        }else{
            templateService.save(data).then(
                template => {
                    commit('saveTemplate',template);
                    commit('setLoading', false);
                    templateService.getAll().then(
                        templates => {
                            commit('getAllSuccess',templates);
                            commit('setLoading', false);
                        },
                        error => {
                            commit('requestFailure', error);
                            commit('setLoading', false);
                        }
                    );
                },
                error => {
                    commit('requestFailure', error);
                    commit('setLoading', false);
                }
            );
        }
    }
}

const mutations = {
    setLoading(state, payload) {
        state.all.loading = payload ;
    },
    getAllSuccess(state, templates) {
        state.all = { items: templates };
    },
    getTemplatesSuccess(state, sections){
        state.sections = sections;
    },
    saveTemplate(state,template){
        state.template = template;
    },
    deleteTemplate(state){
        
        state.all.loading = false;
    },
    requestFailure(state, error) {
        state.all = { error };
    }
}

export const template = {
    namespaced: true,
    state,
    actions,
    mutations
  };
  