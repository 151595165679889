<template>
     <v-container>
         <v-card class="view">
             <v-card-title>
                 Compare <v-spacer/><v-icon>{{ "mdi-plus-circle"}}</v-icon>
             </v-card-title>
             <v-card-text>
                 <v-data-iterator
                 :items="items"
                 >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                            v-for="item in props.items"
                            :key="item.id"
                            cols="12"
                            sm="12"
                            md="8"
                            lg="4"
                            >
                                <v-card>
                                    <v-card-title>
                                        <span>
                                            {{item.firstname}} {{item.lastname}}
                                        </span>
                                        <v-spacer/>
                                        <v-avatar
                                        align="center"
                                        justify="center">
                                        <v-img
                                        v-if="item.userDto && item.userDto.image"
                                        :src="item.userDto.image"
                                        ></v-img>
                                        <v-icon
                                        v-else>
                                        mdi-account-off</v-icon>
                                        </v-avatar>
                                    </v-card-title>
                                    <v-divider/>
                                    <v-card-text>
                                        <v-list dense>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Html</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>CSS</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Java</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Unit-Test</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Postgres</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>MySQL</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Html</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>CSS</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Java</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Unit-Test</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>Postgres</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                            <v-list-item>
                                                <v-list-item-content class="align-end">
                                                    <v-list-item-title>MySQL</v-list-item-title>
                                                    <v-rating
                                                    background-color="blue"
                                                    color="primary"
                                                    full-icon="mdi-checkbox-blank"
                                                    empty-icon="mdi-checkbox-blank-outline"
                                                    dense
                                                    v-model="item.level"
                                                    :length="item.rating.skale"
                                                    size="25"
                                                    >
                                                    </v-rating>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider/>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                 </v-data-iterator>
             </v-card-text>
         </v-card>
     </v-container>
</template>
<script>
export default {
    data() {
        return {
            items: [
                {
                    firstname:"Olaf",
                    lastname:"Matticzk",
                    level:1,
                    rating: {scale:5}
                },
                {
                    firstname:"Hans",
                    lastname:"Meyer",
                    level:5,
                    rating: {scale:5}
                },
                {
                    firstname:"Fridolin",
                    lastname:"Fischer",
                    level:3,
                    rating: {scale:5}
                }
            ]
        }
    },
}
</script>
<style scoped>
  .view {
    width:90%;
    height:90%;
  }
</style>