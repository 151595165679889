<template>
  <v-card class="gridCard">
              <v-card-title>
                <slot name="title"></slot>
                <v-spacer></v-spacer>
                <slot name="action"></slot>
              </v-card-title>
              <v-card-text>
                <slot name="content"></slot>
              </v-card-text>
          </v-card>
</template>

<script>
  export default {

  }
</script>

