import {metaDataService} from '../services';

const state = {
   meta: {}
};

const actions = {
    getMetaData({ commit }) {
        metaDataService.getMyMetaData().then(
            data => {commit('getAllMetaData', data)},
            //error => commit('getByIdFailure', error)
        );
    }
}

const mutations = {
    getAllMetaData (state, data){
        state.meta = data;
    }
}

export const meta = {
    namespaced: true,
    state,
    actions,
    mutations
};