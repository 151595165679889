<template> 
  <v-container class="errorPage">
    <v-row
      align="center"
      justify="center">
      <v-btn  
        fab 
        disabled 
        x-large
        elevation=0>
        <v-icon>mdi-alert-circle-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row
      align="center"
      justify="center">
        <h1>Error</h1>
    </v-row>
    <v-row
      align="center"
      justify="center">
        <h2>Please, check your permissions</h2>
    </v-row>
  </v-container>
</template>

<script>
 export default {
   data () {
    return {
    }
  }
}
</script>

<style>
  .errorPage {
    padding-top: 30%;
  }
</style>