<template>
    <div>
        <v-tooltip
                v-for="subitem in items"
                :key="subitem.skillId"
                top max-width="500" open-delay="300">
            <template v-slot:activator="{ on }">
                <v-chip v-on="on">
                    <v-avatar
                            left
                            :class="calculateDynamicColor(subitem)">
                            <span>{{subitem.level}}</span>
                    </v-avatar>
                    {{ subitem.name }}
                </v-chip>
            </template>
            <div>
                <div><b>{{$t('i18n_level')}}:</b> {{subitem.rating.allowedValues[subitem.level]}}</div>
                <div><b>{{$t('i18n_relevance')}}:</b> {{ subitem.relevance}}</div>
                <div v-if="subitem.description"><b>Beschreibung:</b> {{ subitem.description }}</div>
            </div>
        </v-tooltip>
    </div>
</template>

<script>
    import calculateDynamicColor from "../../utils/color.utils";

    export default {
        props: {
            items: Array
        },
        methods:{
            calculateDynamicColor
        }
    }
</script>
