<template>
        <v-tooltip top max-width="500" open-delay="300">
            <template v-slot:activator="{ on }"  v-if="chip === true">
                <v-chip v-on="on">
                    <v-avatar
                            left
                            :class="colored === true ? calculateDynamicColor(item) : grey"
                            v-if="level === true"
                            >
                            <span>{{item.level}}</span>
                    </v-avatar>
                    {{ item.name }}
                </v-chip>
            </template>
            <template v-slot:activator="{ on }"  v-else-if="text === true">
                    <v-card v-on="on" flat>
                        <v-list-item >
                            <v-list-item-avatar
                            :class="colored === true ? calculateDynamicColor(item) : grey"
                            :size="30"
                            v-if="level === true"
                            >
                                <span >{{item.level}}</span>
                            </v-list-item-avatar>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-card>
            </template>
            <div>
                <div><b>Niveau:</b> {{item.rating.allowedValues[item.level]}}</div>
                <div><b>Relevanz:</b> {{ item.relevance}}</div>
                <div v-if="item.description"><b>Beschreibung:</b> {{ item.description }}</div>
            </div>
        </v-tooltip>
</template>
<script>
    import calculateDynamicColor from "../../../utils/color.utils";

    export default {
        props: {
            item: Object,
            chip: Boolean,
            text: Boolean,
            colored: Boolean,
            level: Boolean

        },
        methods:{
            calculateDynamicColor
        }
    }
</script>
<style scoped>

</style>